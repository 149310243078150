import ReactPlayer from "react-player"
import styles from "./VideoCard.module.css"
import typo from "../../typography.module.css"
import { Each } from "../../common/Each"
import Tag from "../Tag"
import { useNavigate } from "react-router-dom"
import { ReactComponent as PlayIcon } from "../../assets/images/illustrations/il-play-button.svg"

const VideoCard = ({ video, style }) => {

    const navigate = useNavigate();

    return (
        <div className={styles.container} style={style} onClick={() => {
            window.location.href = `/videos/${video.slug}`
        }}>
            <div className={styles.playerWrapper}>
                <ReactPlayer
                    url={video.url}
                    controls={false}
                    muted={true}
                    className={styles.player}
                    width="100%"
                    height="100%"
                    playIcon={<PlayIcon />}
                    light={video.thumbnail}
                    config={
                        {
                            youtube: {
                                playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                            }
                        }
                    }
                />
                <div className={styles.playerOverlay} onClick={(e) => {
                    window.location.href = `/videos/${video.slug}`
                }} />
            </div>
            <div className={styles.info}>
                <div className={[typo.subtitle, styles.title].join(' ')} style={{ fontSize: '1.125rem' }}>{video.title}</div>
                <div className={styles.tags}>
                    <Each of={video.tags} render={(t) => {
                        return (
                            <Tag tag={t} />
                        )
                    }} />
                </div>
            </div>
        </div>
    )

}

export default VideoCard