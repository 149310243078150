
import { useEffect, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-inactive.svg"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { CourseStatus } from "../../common/constants"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import typo from "../../typography.module.css"
import Button from "../Button"
import CourseBadge from "../CourseBadge"
import TeachersBadge from "../TeachersBadge"
import styles from "./PathCourseCard.module.css"

const PathCourseCard = ({ course, style }) => {

    const { width } = useWindowDimensions()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [teachers, setTeachers] = useState([])

    useEffect(() => {
        if (course && course.edition) {
            setTeachers(course.edition.teachers)
        }
        else {
            setTeachers(course.teachers)
        }
    }, [course])

    const percentage = useMemo(() => {
        if (!course) {
            return 0
        }
        const p = Math.floor(course.edition?.hour_progress * 100 / course.edition?.duration) > 100 ? 100 : Math.floor(course.edition?.hour_progress * 100 / course.edition?.duration)
        return isNaN(p) ? 0 : p
    }, [course])

    return (
        <div className={styles.container} style={style}>
            <div className={styles.left}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <div className={typo.subheadline}>
                        {course.name} {course.edition ? `- ${course.edition.name}` : ''}
                    </div>
                    <CourseBadge type={course.type} style={{ width: 'fit-content' }} />
                    <div
                        className={`${typo.body} ${styles.description}`}
                        style={{ opacity: 0.4 }}>
                        {course.edition?.description ?? course.description}
                    </div>
                </div>
                {course.status === CourseStatus.InProgress &&
                    <div className={styles.progressContainer}>
                        <div className={styles.progressInfo}>
                            <div className={styles.numbers}>
                                <div className={styles.number}>
                                    <BookIcon />
                                    <div>
                                        {course.edition.module_progress}<span style={{ opacity: .3 }}>/{course.edition.modules_no}</span>
                                    </div>
                                </div>
                                <div className={styles.number}>
                                    <ClockIcon />
                                    <div>
                                        {course.edition.hour_progress}<span style={{ opacity: .3 }}>/{course.edition.duration}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.percentage}>{percentage}%</div>
                        </div>
                        <div className={styles.progressBar}>
                            <div className={styles.progressFill} style={{ width: `${Math.floor(course.edition.hour_progress * 100 / course.edition.duration)}%` }} />
                        </div>
                    </div>
                }
                {width > 540 &&
                    <Button inverse={course.status !== CourseStatus.ToStart} onClick={() => {
                        navigate(`/my-path/courses/${course.slug}`)
                    }}>
                        {t(course.status === CourseStatus.ToStart ? 'courses.subscribe' : 'courses.enter').toUpperCase()}
                    </Button>
                }
            </div>
            <div className={styles.right}>
                <div className={styles.thumbnail}>
                    <img src={course.edition?.thumbnail ?? course.thumbnail} alt="course-thumbnail"></img>
                </div>
                {width > 540 &&
                    <TeachersBadge
                        style={{ padding: 0 }}
                        teachers={teachers}
                        showQualification={false}
                        pictureStyle={{ width: "32px", height: "32px" }}
                    />
                }
                {width <= 540 &&
                    <Button
                        inverse={course.status !== CourseStatus.ToStart}
                        fullWidth
                        onClick={() => {
                            navigate(`/my-path/courses/${course.slug}`)
                        }}>
                        {t(course.status === CourseStatus.ToStart ? 'courses.subscribe' : 'courses.enter').toUpperCase()}
                    </Button>
                }
            </div>
        </div>
    )

}

export default PathCourseCard
