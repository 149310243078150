import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import React, { useContext, useEffect, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { MDXEditor } from '@mdxeditor/editor'
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { Each } from "../common/Each"
import MainContext from "../common/MainContext"
import Button from "../components/Button"
import MacroCard from "../components/cards/MacroCard"
import OnboardingBannerCard from "../components/cards/OnboardingBannerCard"
import OnboardingCard from "../components/cards/OnboardingCard"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./Dashboard.module.css"
import SurveyCard from "../components/cards/SurveyCard"
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg"
import LessonsCarousel from '../components/LessonsCarousel'
import VideoCard from "../components/cards/VideoCard"

const Dashboard = () => {

    const navigate = useNavigate()
    const context = useContext(MainContext)
    const [macros, setMacros] = useState(null)
    const [banners, setBanners] = useState(null)
    const [selectedStep, setSelectedStep] = useState(null)
    const splideRef = useRef(null)

    const [lessons, setLessons] = useState([])
    const [courses, setCourses] = useState([])
    const [videos, setVideos] = useState([])
    const [loading, setLoading] = useState(false)
    const [coursesLoading, setCoursesLoading] = useState(false)

    const refreshSplide = () => {
        if (splideRef && splideRef.current) {
            splideRef.current.splide.go(0)
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })

        document.addEventListener('sidenav-open', refreshSplide)
        document.addEventListener('sidenav-closed', refreshSplide)

        const getLessons = async () => {
            setLoading(true)
            try {
                let lessons = await api.get("/user/lessons")
                setLessons(lessons)
            }
            catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

        const getCourses = async () => {
            setCoursesLoading(true)
            try {
                let courses = await api.get("/user/courses")
                setCourses(courses)
            }
            catch (e) {
                console.error(e)
            }
            setCoursesLoading(false)
        }

        const getVideos = async () => {
            try {
                let videos = await api.get("/videos")
                setVideos(videos)
            }
            catch (e) {
                console.error(e)
            }
        }

        getVideos()
        getCourses()
        getLessons()

        return () => {
            document.removeEventListener('sidenav-open', refreshSplide)
            document.removeEventListener('sidenav-closed', refreshSplide)
        }

    }, [])

    useEffect(() => {
        if (context.onboarding) {
            let notCompleted = context.onboarding.steps.find(s => !s.completed)
            if (notCompleted) {
                setSelectedStep(notCompleted)
            }
            else {
                setSelectedStep(context.onboarding.steps[0])
            }
        }
    }, [context.onboarding])

    useEffect(() => {
        const getMacros = async () => {
            try {
                let macros = await api.get("/macros")
                setMacros(macros)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!macros) {
            getMacros()
        }
    }, [macros])

    useEffect(() => {
        const getBanners = async () => {
            try {
                let banners = await api.get("/banners")
                setBanners(banners)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!banners) {
            getBanners()
        }
    }, [banners])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                {banners &&
                    <div className={styles.section} style={{ borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                        <div className={styles.sectionInner}>
                            <Splide ref={splideRef} aria-label="Banner" options={{
                                rewind: true,
                                autoplay: true,
                                speed: 1000
                            }}>
                                <Each of={banners} render={(banner) => {
                                    return (
                                        <SplideSlide>
                                            <div className={styles.banner}>
                                                <div className={styles.bannerLeft}>
                                                    <div className={typo.title}>
                                                        {banner.title.replace('{USER}', context.user?.name)}
                                                    </div>
                                                    <div className={`${typo.body} ${styles.bannerDescription}`}>
                                                        <MDXEditor markdown={banner.description} readOnly />
                                                    </div>
                                                    <Button
                                                        onClick={() => {
                                                            if (banner.url.startsWith('#')) {
                                                                let elem = document.querySelector(banner.url)
                                                                if (elem) {
                                                                    elem.scrollIntoView({ behavior: 'smooth' })
                                                                }
                                                            }
                                                            else {
                                                                navigate(banner.url)
                                                            }
                                                        }} accentColor={'var(--tertiary)'} style={{ padding: '1rem 1.5rem', fontSize: '1rem' }}>{banner.call_to_action.toUpperCase()}</Button>
                                                </div>
                                                <div className={styles.bannerRight}>
                                                    {banner.media_type === 'video' &&
                                                        <div className={styles.playerWrapper}>
                                                            <div className={styles.playerWrapper}>
                                                                <ReactPlayer
                                                                    url={banner.media}
                                                                    controls
                                                                    width="100%"
                                                                    height="100%"
                                                                    className={styles.player}
                                                                    playIcon={<PlayIcon />}
                                                                    light={"https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/thumbnails/cover-welcome.png"}
                                                                    config={
                                                                        {
                                                                            youtube: {
                                                                                playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                                            }
                                                                        }
                                                                    }
                                                                    onStart={() => {
                                                                        splideRef.current.splide.Components.Autoplay.pause();
                                                                    }}
                                                                    onPlay={() => {
                                                                        splideRef.current.splide.Components.Autoplay.pause();
                                                                    }}
                                                                    onPause={() => {
                                                                        splideRef.current.splide.Components.Autoplay.play();
                                                                    }}
                                                                    onEnded={() => {
                                                                        splideRef.current.splide.Components.Autoplay.play();
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {banner.media_type === 'image' &&
                                                        <div className={styles.bannerImage}>
                                                            <img src={banner.media} alt="" />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    )
                                }} />

                            </Splide>
                        </div>
                    </div>
                }
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <SurveyCard />
                        <div className={styles.onboarding} id="onboarding">
                            <div className={styles.onboardingLeft}>
                                {context.onboarding && selectedStep &&
                                    <OnboardingCard onboarding={context.onboarding} selectedStep={{ state: selectedStep, setState: setSelectedStep }} />
                                }
                            </div>
                            <div className={styles.onboardingRight}>
                                {context.onboarding && selectedStep &&
                                    <OnboardingBannerCard step={selectedStep} />
                                }
                            </div>
                        </div>
                        <div className={typo.subtitle}
                            style={{
                                fontSize: '1.25rem',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '2rem'
                            }}>
                            ESPLORA AREE DI INTERESSE
                        </div>
                        {macros &&
                            <div className={styles.macros} id="careers">
                                <Each of={macros} render={(macro) => {
                                    return (
                                        <div className={styles.macro}>
                                            <MacroCard macro={macro} />
                                        </div>
                                    )
                                }} />
                            </div>
                        }
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <LessonsCarousel lessons={lessons} courses={courses} loading={loading} />
                        <div className={typo.subtitle}>Video per te</div>
                        <div className={styles.videos}>
                            <Each of={videos} render={(video) => {
                                return (
                                    <div className={styles.video}>
                                        <VideoCard video={video} style={{maxWidth: 'none'}}/>
                                    </div>
                                )
                            }} />
                        </div>

                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )
}

export default Dashboard
