import { AnimatePresence, motion } from "framer-motion"
import { useContext, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import ReactPlayer from "react-player"
import { useNavigate, useParams } from "react-router-dom"
import TextareaAutosize from 'react-textarea-autosize'
import { v4 as uuidv4 } from 'uuid'
import api from "../api"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import { ReactComponent as DoubleCheckIcon } from "../assets/images/icons/ic-double-check.svg"
import { ReactComponent as DownloadIcon } from "../assets/images/icons/ic-download.svg"
import { ReactComponent as FileIcon } from "../assets/images/icons/ic-file.svg"
import { ReactComponent as SendIcon } from "../assets/images/icons/ic-send.svg"
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg"
import { Each } from "../common/Each"
import MainContext from "../common/MainContext"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Loader from "../components/Loader"
import Skeleton from "../components/Skeleton"
import Spinner from "../components/Spinner"
import TextInput from "../components/TextInput"
import WritingLoader from "../components/WritingLoader"
import Clock from "../components/animated/Clock"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import { capitalize, formatDateV2, formatFileSize, formatTimeV2, getLocaleISOString, hexToChat } from "../utils"
import { sendMessage } from "../websocket"
import styles from "./Messages.module.css"
import { ProfileType } from "../common/constants"

const { Buffer } = require("buffer")

const Messages = () => {

    const context = useContext(MainContext)
    const { width } = useWindowDimensions()
    const [contacts, setContacts] = useState([])
    const [filteredContacts, setFilteredContacts] = useState([])
    const [selectedContact, setSelectedContact] = useState(null)
    const [body, setBody] = useState('')
    const [loadingContacts, setLoadingContacts] = useState(true)
    const [whoIsWriting, setWhoIsWriting] = useState(null)
    const [sendDisabled, setSendDisabled] = useState(true)
    const [reconnecting, setReconnecting] = useState(false)
    const [file, setFile] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [fullScreenImage, setFullScreenImage] = useState(null)
    const [chatId, setChatId] = useState(null)

    const textRef = useRef(null)
    const endRef = useRef(null);
    const fileRef = useRef(null)
    const [loadMoreRef, isInView] = useInView({
        triggerOnce: false,
        rootMargin: '0px 0px',
    })

    //Uso un ref allo stato per renderlo accessibile all'interno dell'event handler del messaggio (onMessageReceived)
    const [messagesQueue, _setMessagesQueue] = useState([])
    const messagesQueueRef = useRef(messagesQueue)
    const setMessagesQueue = data => {
        messagesQueueRef.current = data;
        _setMessagesQueue(data);
    }

    const [touchStart, _setTouchStart] = useState(null)
    const [touchEnd, _setTouchEnd] = useState(null)
    const touchStartRef = useRef(touchStart)
    const touchEndRef = useRef(touchEnd)

    const setTouchStart = data => {
        touchStartRef.current = data;
        _setTouchStart(data);
    }

    const setTouchEnd = data => {
        touchEndRef.current = data;
        _setTouchEnd(data);
    }


    useEffect(() => {

        const processMessage = async (receivedMessage) => {
            await new Promise((resolve) => {
                //Ricevuto
                if (receivedMessage.type === "received") {
                    setWhoIsWriting(null)
                    let sender_id = receivedMessage.sender_id
                    let updatedContacts = contacts.map(c => {
                        if (c.profile.id === sender_id) {
                            if (!c.messages.page.map(m => m.id).includes(receivedMessage.id)) {
                                c.messages.page.push(receivedMessage)
                                c.messages.metadata.unread += 1
                            }
                            if (c.profile.id === selectedContact?.profile.id) {
                                context.setUnreadMessages(context.unreadMessages - c.messages.metadata.unread)
                                sendMessage({
                                    type: "read",
                                    receiver_id: selectedContact.profile.id,
                                    receiver_type: selectedContact.profile.type,
                                    sender_type: ProfileType.User
                                })
                                c.messages.metadata.unread = 0
                            }
                        }
                        return c
                    })
                    const selected = updatedContacts.find(c => c.id === selectedContact?.id)
                    if (selected) {
                        setSelectedContact({ ...selected })
                    }
                    setContacts(updatedContacts)
                    setFilteredContacts(updatedContacts)
                    scrollToBottom()
                }
                //Conferma di invio
                if (receivedMessage.type === "sent") {
                    let recever_id = receivedMessage.receiver_id

                    let updatedContacts = contacts.map(c => {
                        if (c.profile.id === recever_id) {
                            c.messages.page = c.messages.page.map(m => {
                                if (m.body === receivedMessage.body && m.sending === true) {
                                    m.id = receivedMessage.id
                                    m.created_at = receivedMessage.created_at
                                    m.sending = false
                                }
                                return m
                            })
                        }
                        return c
                    })
                    const selected = updatedContacts.find(c => c.id === selectedContact?.id)
                    if (selected) {
                        setSelectedContact({ ...selected })
                    }
                    setContacts(updatedContacts)
                    setFilteredContacts(updatedContacts)
                    scrollToBottom()
                }
                //Sta scrivendo
                if (receivedMessage.type === "writing") {
                    setWhoIsWriting({ id: receivedMessage.sender_id, type: receivedMessage.sender_type })
                }
                //Ricevuta di lettura
                if (receivedMessage.type === "read") {
                    if (receivedMessage.sender_id === selectedContact?.profile.id &&
                        receivedMessage.sender_type === selectedContact?.profile.type) {
                        let updatedContacts = contacts.map(c => {
                            if (c.profile.id === receivedMessage.sender_id) {
                                c.messages.page = c.messages.page.map(m => {
                                    if (!m.read_at) {
                                        let read_at = getLocaleISOString()
                                        m.read_at = read_at
                                    }
                                    return m
                                })
                            }
                            return c
                        })
                        setContacts(updatedContacts)
                    }
                }
                setMessagesQueue(messagesQueueRef.current.filter(m => m.processing_id !== receivedMessage.processing_id))
                resolve()
            })
        }
        for (let message of messagesQueue) {
            processMessage(message)
        }
    }, [messagesQueue])

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const encodedChat = queryParams.get("chat")
        if (encodedChat && encodedChat.length > 0) {
            const decodeChat = Buffer.from(encodedChat, "base64").toString()
            const id = parseInt(decodeChat.split("_")[0])
            setChatId(id)
        }

        const getMessages = async () => {
            setLoadingContacts(true)
            try {
                let contacts = await api.get("/messages")
                let sorted = contacts.sort((a, b) => {
                    // Recupera l'ultimo messaggio per ogni contatto
                    let lastMessageA = a.messages.page.length > 0 ? a.messages.page[a.messages.page.length - 1] : null;
                    let lastMessageB = b.messages.page.length > 0 ? b.messages.page[b.messages.page.length - 1] : null;

                    // Se non ci sono messaggi, considera una data molto vecchia (o infinita)
                    let dateA = lastMessageA ? new Date(lastMessageA.created_at) : new Date(0);
                    let dateB = lastMessageB ? new Date(lastMessageB.created_at) : new Date(0);

                    return dateB - dateA; // Ordine decrescente
                });
                setContacts(sorted)
                setFilteredContacts(sorted)
            }
            catch (e) {
                console.error(e)
            }
            setLoadingContacts(false)
        }

        getMessages()

        context.setNotificationBanner(null)

        const onMessageReceived = (event) => {
            event.detail.processing_id = uuidv4()
            setMessagesQueue([...messagesQueueRef.current, event.detail])
        }

        const onReconnecting = () => {
            setReconnecting(true)
        }

        const onConnected = () => {
            setReconnecting(false)
            if (body.trim().length > 0 || file) {
                setSendDisabled(false)
            }
        }

        window.onpopstate = (e) => {
            setSelectedContact(null)
        }

        document.addEventListener('message-received', onMessageReceived);
        document.addEventListener('websocket-reconnecting', onReconnecting);
        document.addEventListener('websocket-connected', onConnected);

        return () => {
            // Rimuovi l'handler quando il componente viene smontato
            document.removeEventListener('message-received', onMessageReceived);
        };

    }, [])

    useEffect(() => {
        if (whoIsWriting) {
            setTimeout(() => {
                setWhoIsWriting(null)
            }, 2500)
        }
    }, [whoIsWriting])

    useEffect(() => {
        if (selectedContact) {
            sendMessage({
                type: "read",
                sender_type: ProfileType.User,
                receiver_id: selectedContact.profile.id,
                receiver_type: selectedContact.profile.type
            })
            let updatedContacts = contacts.map(c => {
                if (c.id === selectedContact?.id) {
                    context.setUnreadMessages(context.unreadMessages - c.messages.metadata.unread)
                    c.messages.metadata.unread = 0
                    c.messages.page = c.messages.page.map(m => {
                        if (!m.read_at && m.type === 'received') {
                            let read_at = getLocaleISOString()
                            m.read_at = read_at
                        }
                        return m
                    })
                }
                return c
            })
            setContacts(updatedContacts)

            const options = { passive: false };
            window.addEventListener("touchstart", onTouchStart, options);
            window.addEventListener("touchmove", onTouchMove, options);
            window.addEventListener("touchend", onTouchEnd, options);
        }
        else {
            setWhoIsWriting(null)
            setTouchStart(null)
            setTouchEnd(null)
            window.removeEventListener("touchstart", onTouchStart)
            window.removeEventListener("touchmove", onTouchMove)
            window.removeEventListener("touchend", onTouchEnd)
        }
    }, [selectedContact])

    useEffect(() => {
        if (!reconnecting) {
            contacts.map(c => {
                c.messages.page.filter(m => m.sending).map(m => {
                    sendMessage({
                        type: "sent",
                        sender_type: ProfileType.User,
                        receiver_id: c.profile.id,
                        receiver_type: c.profile.type,
                        body: m.body
                    })
                })
                return c
            })
        }
    }, [reconnecting])

    useEffect(() => {
        const loadMoreMessages = async (profile_id, profile_type, page = 1, per = 20) => {
            try {
                let messages = await api.get(`/messages/${profile_id}?profile_type=${profile_type}&page=${page}&per=${per}`)
                let updatedContacts = contacts.map(c => {
                    if (c.profile.id === profile_id) {
                        for (let m of messages.page) {
                            if (!c.messages.page.map(m => m.id).includes(m.id)) {
                                c.messages.page.push(m)
                            }
                        }
                        // c.messages.page = c.messages.page.sort((a, b) => { return new Date(a.created_at) - new Date(b.created_at) })
                        c.messages.metadata = messages.metadata
                    }
                    return c
                })

                setContacts(updatedContacts)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (isInView && selectedContact) {
            if (selectedContact.messages.page.length < selectedContact.messages.metadata.total) {
                loadMoreMessages(selectedContact.profile.id, selectedContact.profile.type, selectedContact.messages.metadata.page + 1)
            }
        }
    }, [isInView])

    useEffect(() => {
        if (file) {
            setSendDisabled(false)
        }
        else {
            if (body.trim().length > 0) {
                setSendDisabled(false)
            }
            else {
                setSendDisabled(true)
            }
        }
    }, [file])

    useEffect(() => {
        if (loadingContacts === false && chatId) {
            const selectedContact = contacts.find((c) => c.id === chatId)
            setSelectedContact({ ...selectedContact })
        }
    }, [loadingContacts])

    const onTouchStart = (e) => {
        if (e.touches.length === 1) {
            const touch = e.touches[0];
            if (touch.clientX < 20) {
                e.preventDefault()
                setTouchEnd(null)
                setTouchStart(touch.clientX)
            }
        }
    }

    const onTouchMove = (e) => {
        if (touchStartRef.current && touchStartRef.current < 30) {
            if (e.touches.length === 1) {
                const touch = e.touches[0];
                setTouchEnd(touch.clientX)
            }
        }
    }

    const onTouchEnd = () => {
        if (!touchStartRef || !touchEndRef) return
        const distance = touchStartRef.current - touchEndRef.current
        const isRightSwipe = distance < -10
        if (isRightSwipe && distance < -(window.innerWidth * 0.6)) {
            setTouchEnd(window.innerWidth + 10)
            setTimeout(() => {
                setSelectedContact(null)
            }, 100)
        }
        else {
            setTouchEnd(null)
            setTouchStart(null)
        }
    }

    function groupMessagesByDate(messages) {
        const groupedMessages = {};

        messages.forEach(message => {
            const messageDate = message.created_at.slice(0, 10); // Extract date (up to day)

            if (groupedMessages[messageDate]) {
                groupedMessages[messageDate].push(message);
            } else {
                groupedMessages[messageDate] = [message];
            }
        });

        // Sort dictionary keys by date
        const sortedKeys = Object.keys(groupedMessages).sort((a, b) => new Date(b) - new Date(a));

        // Create a new sorted dictionary
        const sortedGroupedMessages = {};
        sortedKeys.forEach(key => {
            sortedGroupedMessages[key] = groupedMessages[key];
        });

        // Sort messages within each key by date
        for (const key in sortedGroupedMessages) {
            sortedGroupedMessages[key].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        }

        return sortedGroupedMessages;
    }

    const send = async () => {
        let created_at = getLocaleISOString()
        if (!file) {
            sendMessage({
                type: "sent",
                sender_type: ProfileType.User,
                receiver_id: selectedContact.profile.id,
                receiver_type: selectedContact.profile.type,
                body: body,
            })

            let message = { body: body, type: "sent", created_at: created_at, sending: true }
            let updatedContacts = contacts.map(c => {
                if (c.profile.id === selectedContact.profile.id) {
                    c.messages.page.push(message)
                    c.messages.metadata.total += 1
                }
                return c
            })
            const selected = updatedContacts.find(c => c.id === selectedContact?.id)
            if (selected) {
                setSelectedContact({ ...selected })
            }
            setContacts(updatedContacts)
            setBody('')
            setSendDisabled(true)
        }
        else {
            let upload_id = uuidv4()
            let message = {
                sender_type: ProfileType.User,
                body: body,
                type: "sent",
                created_at: created_at,
                upload_id: upload_id,
                medias: [
                    {
                        url: imageUrl,
                        name: file.name,
                        size: file.size
                    }
                ],
                sending: true
            }

            let updatedContacts = contacts.map(c => {
                if (c.profile.id === selectedContact.profile.id) {
                    c.messages.page.push(message)
                    c.messages.metadata.total += 1
                }
                return c
            })
            const selected = updatedContacts.find(c => c.id === selectedContact?.id)
            if (selected) {
                setSelectedContact({ ...selected })
            }
            setContacts(updatedContacts)
            setBody('')
            setFile(null)
            setImageUrl(null)
            try {
                let uploaded = await uploadMessageFile(file, upload_id)
                let updatedContacts = contacts.map(c => {
                    if (c.profile.id === selectedContact.profile.id) {
                        c.messages.page.map(m => {
                            if (m.upload_id === upload_id) {
                                m.medias[0].url = uploaded.url
                            }
                            return m
                        })
                    }
                    return c
                })
                setContacts(updatedContacts)
                message.receiver_id = selectedContact.profile.id
                message.receiver_type = selectedContact.profile.type
                sendMessage(message)

            }
            catch (e) {
                let updatedContacts = contacts.map(c => {
                    if (c.profile.id === selectedContact.profile.id) {
                        c.messages.page = c.messages.page.filter(m => m.upload_id !== upload_id)
                        c.messages.metadata.total -= 1
                    }
                    return c
                })
                setContacts(updatedContacts)
            }
        }
        textRef.current.focus()
    }

    const uploadMessageFile = async (file, upload_id) => {
        const formData = new FormData();
        formData.append("upload_id", upload_id)
        formData.append("file", file, file.name);


        return await api.post("/messages/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    }

    const handleOnChange = async (event) => {
        if (body.length > event.target.value.length) {
            setBody(event.target.value)
            if (event.target.value.length > 0 && event.target.value.trim().length > 0) {
                setSendDisabled(false)
            }
            else {
                if (!file) {
                    setSendDisabled(true)
                }
            }
            return
        }
        setBody(event.target.value)
        if (event.target.value.length > 0 && event.target.value.trim().length > 0) {
            setSendDisabled(false)
            if (selectedContact) {
                sendMessage({
                    type: "writing",
                    sender_type: ProfileType.User,
                    receiver_id: selectedContact.profile.id,
                    receiver_type: selectedContact.profile.type
                })
            }
        }
        else {
            if (!file) {
                setSendDisabled(true)
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            if (!sendDisabled) {
                send()
            }
            textRef.current.focus()
        }
    };

    const search = (q) => {
        if (q.trim().length > 0) {
            let results = contacts.filter(c => {
                return `${c.name}${c.surname}`.replace(' ', '').toLowerCase().includes(q.toLowerCase().trim().replace(' ', ''))
            })
            setFilteredContacts(results)
        }
        else {
            setFilteredContacts(contacts)
        }
    }

    const scrollToBottom = () => {
        if (endRef && endRef.current) {
            endRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }

    const handleFileOnChange = (event) => {
        let file = event.target.files[0]
        setFile(file)
        setImageUrl(URL.createObjectURL(file))
        event.target.value = null
    }

    const getFileMediaType = (file) => {
        const parts = file.name.split('.');
        const extension = parts[parts.length - 1].toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'gif', 'svg', 'webp', 'heic', 'raw']
        const videoExtensions = ['mp4', 'mov', 'mkv', 'avi']
        if (imageExtensions.includes(extension)) {
            return 'image'
        }
        else if (videoExtensions.includes(extension)) {
            return 'video'
        }
        else {
            return 'file'
        }
    }


    const getUrlMediaType = (url) => {
        const parts = url.split('/');
        const fileName = parts[parts.length - 1]
        const file_parts = fileName.split('.')
        const extension = file_parts[file_parts.length - 1].toLowerCase()
        const imageExtensions = ['jpg', 'jpeg', 'gif', 'svg', 'webp', 'heic', 'raw']
        const videoExtensions = ['mp4', 'mov', 'mkv', 'avi']
        if (imageExtensions.includes(extension)) {
            return 'image'
        }
        else if (videoExtensions.includes(extension)) {
            return 'video'
        }
        else {
            return 'file'
        }
    }

    const getContactLastMessage = (contact) => {
        const lastMessage = contact.messages.page.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at) })[0]
        var result = ""
        if (lastMessage) {
            if (lastMessage.medias && lastMessage.medias.length > 0) {
                const mediaType = getUrlMediaType(lastMessage.medias[0].url)
                switch (mediaType) {
                    case 'image':
                        result += '📷'
                        break
                    case 'video':
                        result += '📹'
                        break
                    default:
                        result += '📁'
                }

                if (lastMessage.body.trim().length > 0) {
                    result += ` ${lastMessage.body}`
                }
                else {
                    switch (mediaType) {
                        case 'image':
                            result += ` immagine`
                            break
                        case 'video':
                            result += ' video'
                            break
                        default:
                            result += ' file'
                    }
                }
            }
            else {
                result += lastMessage.body
            }
        }

        return result
    }

    return (
        <HeaderFooterLayout hideFooter>
            <div className={styles.container}>
                <div className={styles.contacts}>
                    <div className={typo.title} style={{ padding: '0 1rem' }}>Chat</div>
                    <div className={styles.search}>
                        <TextInput type="search" placeholder="Cerca.." style={{ backgroundColor: "transparent" }} onKeyUp={search}></TextInput>
                    </div>
                    <div className={styles.contactList}>
                        {loadingContacts &&
                            <Each of={[0, 1, 2]} render={() => {
                                return (
                                    <div className={styles.skeletonContact}>
                                        <Skeleton type="circle" width="46px" height="46px" />
                                        <div className={styles.skeletonContactInfo}>
                                            <Skeleton type="rect" borderRadius="12px" width="200px" height="10px" />
                                            <Skeleton type="rect" borderRadius="8px" width="150px" height="10px" />
                                        </div>
                                    </div>
                                )
                            }} />
                        }
                        <Each of={filteredContacts} render={(contact) => {
                            return (
                                <div className={`${styles.contact} ${selectedContact?.id === contact.id ? styles.active : ''}`}
                                    onClick={() => {
                                        setSelectedContact({ ...contact })
                                    }}>
                                    <img src={contact.picture} alt="" className={styles.picture} />
                                    <div className={styles.contactInfo}>
                                        <div className={styles.contactName}>{contact.name} {contact.surname}</div>
                                        {contact.messages.page.length > 0 &&
                                            <div className={styles.contactLastMessage} style={{ opacity: contact.messages.metadata.unread > 0 ? 0.88 : 0.4 }}>
                                                {(!whoIsWriting || whoIsWriting.id !== contact.profile.id || whoIsWriting.type !== contact.profile.type) &&
                                                    <>
                                                        {getContactLastMessage(contact)}
                                                    </>
                                                }
                                                {whoIsWriting && whoIsWriting.id === contact.profile.id && whoIsWriting.type === contact.profile.type &&
                                                    <>Sta scrivendo...</>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.contactMessageInfo}>
                                        {contact.messages.page.length > 0 &&
                                            <div className={styles.lastMessageTime} style={{ opacity: contact.messages.metadata.unread > 0 ? 0.88 : 0.4 }}>
                                                {formatTimeV2(contact.messages.page.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at) })[0].created_at)}
                                            </div>
                                        }
                                        {contact.messages.metadata.unread > 0 &&
                                            <div className={styles.unreadBadge}>
                                                {contact.messages.metadata.unread}
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }} />
                    </div>
                </div>

                <AnimatePresence>
                    {selectedContact &&
                        <motion.div
                            initial={{ x: '100%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%', transition: { duration: .2 } }}
                            transition={{ duration: .2 }}
                            style={{ left: width < 768 ? touchEndRef.current : 0 }}
                            className={`${styles.chatContainer} ${width <= 768 && selectedContact ? styles.visible : ''} ${!touchEndRef.current ? styles.leftAnimate : ''}`}>

                            {/* HEADER */}
                            <div
                                className={styles.header}>
                                <ChevronIcon className={styles.backToChat} onClick={() => { setSelectedContact(null) }} />
                                <img src={selectedContact.picture} alt="" className={styles.headerPicture} />
                                <div className={styles.headerInfo}>
                                    <div className={styles.headerName}>
                                        {selectedContact.name} {selectedContact.surname}
                                    </div>
                                    <div className={styles.headerAvailable}>
                                        <div className={styles.available} />
                                        Disponibile
                                    </div>
                                </div>

                                {reconnecting &&
                                    <div className={styles.reconnecting}>
                                        <Spinner />
                                        Riconnessione
                                    </div>
                                }
                            </div>

                            {/* CHAT */}
                            <div className={styles.chat}>
                                <div id="end" ref={endRef} />
                                <AnimatePresence>
                                    {whoIsWriting && whoIsWriting.id === selectedContact?.profile.id && whoIsWriting.type === selectedContact?.profile.type &&
                                        <motion.div
                                            initial={{ y: '200%' }}
                                            animate={{ y: 0 }}
                                            transition={{ duration: .2, ease: 'easeInOut' }}
                                            exit={{ y: '200%', transition: { duration: .2, ease: 'easeInOut' } }}
                                            className={`${styles.messageContainer} ${styles.received}`}>
                                            <div className={`${styles.message} ${styles.received}`}>
                                                <WritingLoader />
                                            </div>
                                            <div className={styles.messageTime}>Sta scrivendo..</div>
                                        </motion.div>
                                    }
                                </AnimatePresence>

                                {Object.keys(groupMessagesByDate(selectedContact.messages.page)).map((key, index) => {
                                    return (
                                        <div key={key}>
                                            <div className={styles.messageDay}>
                                                {capitalize(formatDateV2(key))}
                                            </div>
                                            <Each of={groupMessagesByDate(selectedContact.messages.page)[key]} render={(message) => {
                                                return (
                                                    <div
                                                        id={`message-${message.id}`} className={`${styles.messageContainer} ${message.type === "sent" ? styles.sent : styles.received}`}>
                                                        <div className={`${styles.message} ${message.type === "sent" ? styles.sent : styles.received}`}>
                                                            {message.medias?.length > 0 &&
                                                                <>
                                                                    {getUrlMediaType(message.medias[0].name) === 'image' &&
                                                                        <ImageLoader src={message.medias[0].url} onClick={() => {
                                                                            setFullScreenImage(message.medias[0].url)
                                                                        }} />
                                                                    }
                                                                    {getUrlMediaType(message.medias[0].name) === 'video' &&
                                                                        <div className={styles.playerWrapper} style={{ maxWidth: '350px' }}>
                                                                            <ReactPlayer
                                                                                url={message.medias[0].url}
                                                                                controls
                                                                                width="100%"
                                                                                height="100%"
                                                                                className={styles.player}
                                                                                playIcon={<PlayIcon />}
                                                                                light={true}
                                                                                config={
                                                                                    {
                                                                                        youtube: {
                                                                                            playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {getUrlMediaType(message.medias[0].name) === 'file' &&
                                                                        <div className={styles.file}>
                                                                            <div className={styles.fileInfo}>
                                                                                <div className={styles.fileName}>
                                                                                    {message.medias[0].name}
                                                                                </div>
                                                                                <div className={styles.fileSize}>
                                                                                    {formatFileSize(message.medias[0].size)}
                                                                                </div>
                                                                            </div>
                                                                            <button className={styles.downloadFile} onClick={async () => {
                                                                                const outsideRes = await fetch(message.medias[0].url);
                                                                                const blob = await outsideRes.blob();
                                                                                const url = URL.createObjectURL(blob);

                                                                                const link = document.createElement("a");
                                                                                link.href = url;
                                                                                link.download = message.medias[0].name;
                                                                                document.body.appendChild(link);
                                                                                // Simula il clic sul link
                                                                                link.click();
                                                                                // Rimuovi il link dal corpo del documento
                                                                                document.body.removeChild(link);
                                                                            }}>
                                                                                <DownloadIcon className={styles.downloadIcon}></DownloadIcon>
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }

                                                            {message.body.trim().length > 0 &&
                                                                <div className={styles.messageBody}>
                                                                    {message.body}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className={styles.messageTime}>
                                                            {formatTimeV2(message.created_at)}
                                                            {message.type === "sent" && message.sending &&
                                                                <Clock className={styles.clock} />
                                                            }
                                                            {message.type === "sent" && !message.sending && !message.read_at &&
                                                                <CheckIcon style={{ width: '12px', height: '12px' }} />
                                                            }
                                                            {message.type === "sent" && !message.sending && message.read_at &&
                                                                <DoubleCheckIcon style={{ width: '16px', height: '16px' }} />
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }} />
                                        </div>
                                    )
                                })
                                }
                                <div id="load-more" className={styles.loadMore} ref={loadMoreRef}></div>
                                {selectedContact.messages.page.length < selectedContact.messages.metadata.total &&
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '.5rem' }}>
                                        <Spinner color="black" />
                                    </div>
                                }
                            </div>

                            {/* BOTTOM BAR */}
                            <div
                                className={styles.bottomBar}>
                                {file &&
                                    <div className={styles.innerFile}>

                                        {getFileMediaType(file) === 'image' &&
                                            <>
                                                <button className={styles.closeFile} onClick={() => { setFile(null) }}>
                                                    <CloseIcon style={{ width: '16px', height: '16px', minHeight: '16px', minWidth: '16px' }} />
                                                </button>
                                                <img src={imageUrl} alt="" className={styles.fileImage} />
                                            </>
                                        }

                                        {getFileMediaType(file) === 'video' &&
                                            <>
                                                <button className={styles.closeFile} onClick={() => { setFile(null) }}>
                                                    <CloseIcon style={{ width: '16px', height: '16px', minHeight: '16px', minWidth: '16px' }} />
                                                </button>
                                                <div className={styles.playerWrapper}>
                                                    <ReactPlayer
                                                        url={imageUrl}
                                                        controls
                                                        width="100%"
                                                        height="100%"
                                                        className={styles.player}
                                                        playIcon={<PlayIcon />}
                                                        light={true}
                                                        config={
                                                            {
                                                                youtube: {
                                                                    playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </>
                                        }

                                        {getFileMediaType(file) === 'file' &&
                                            <div className={styles.file}>
                                                <div className={styles.fileInfo}>
                                                    <div className={styles.fileName}>
                                                        {file.name}
                                                    </div>
                                                    <div className={styles.fileSize}>
                                                        {formatFileSize(file.size)}
                                                    </div>
                                                </div>
                                                <button className={styles.deleteFile} onClick={() => {
                                                    setFile(null)
                                                }}>
                                                    <CloseIcon className={styles.deleteIcon}></CloseIcon>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className={styles.inner}>
                                    <input type='file' id='file' ref={fileRef} style={{ display: 'none' }} onChange={handleFileOnChange} multiple={false} />
                                    <button className={styles.fileButton} onClick={() => {
                                        if (fileRef && fileRef.current) {
                                            fileRef.current.click()
                                        }
                                    }}>
                                        <FileIcon />
                                    </button>
                                    <TextareaAutosize ref={textRef} value={body} minRows={1} maxRows={5} type="text" className={styles.messageInput} placeholder="Scrivi qualcosa.." onChange={handleOnChange} onKeyDown={handleKeyDown} />
                                    <button className={styles.send} onClick={send} disabled={sendDisabled}>
                                        <SendIcon />
                                    </button>
                                </div>
                            </div>

                            {fullScreenImage &&
                                <FullScreenImage src={fullScreenImage} onClose={() => { setFullScreenImage(null) }} />
                            }

                        </motion.div>
                    }

                </AnimatePresence>
                {!selectedContact &&
                    <div className={typo.headline}
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem'
                        }}>
                        Seleziona una conversazione per inviare un messaggio.
                    </div>
                }

            </div>
        </HeaderFooterLayout>
    )
}

function ImageLoader({ src, onClick }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleImageLoaded = () => {
        setIsLoading(false); // Imposta lo stato su false quando l'immagine è stata caricata
    };

    const handleLoadStart = () => {
        setIsLoading(true)
    }

    return (
        <>
            {isLoading &&
                <div className={styles.imageLoader}>
                    <Loader />
                </div>
            }
            <motion.img layoutId={src} src={src} alt="" className={styles.messageImage} onLoadStart={handleLoadStart} onLoad={handleImageLoaded} onClick={onClick} />
        </>
    );
}

function FullScreenImage({ src, onClose }) {
    return (
        <div className={styles.fullScreenImageContainer}>
            <button className={styles.closeFile} onClick={onClose}>
                <CloseIcon style={{ width: '16px', height: '16px', minHeight: '16px', minWidth: '16px' }} />
            </button>
            <AnimatePresence>
                <motion.img src={src} alt="" className={styles.fullScreenImage} layoutId={src} />
            </AnimatePresence>
        </div>
    )
}

export default Messages
