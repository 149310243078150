import { Splide, SplideSlide } from "@splidejs/react-splide"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom"
import api from "../api"
import { ReactComponent as RetryIcon } from "../assets/images/icons/ic-arrow-circular.svg"
import { ReactComponent as ErrorIcon } from "../assets/images/icons/ic-error.svg"
import { ReactComponent as QuestionsIcon } from "../assets/images/icons/ic-questions.svg"
import { Each } from "../common/Each"
import Button from "../components/Button"
import CircularProgess from "../components/CircularProgress"
import Skeleton from "../components/Skeleton"
import Tag from "../components/Tag"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import TestForm from "../components/tests/TestForm"
import TestResult from "../components/tests/TestResult"
import { CorrectionType, TestType } from "../enums"
import styles from "./Test.module.css"
import { TestStatus } from "../common/constants"
import { calcLastUpdate } from "../utils"
import useWindowDimensions from "../common/hooks/useWindowDimensions"

let submitTimeout = null

const Test = () => {
  const { t } = useTranslation()
  const { testId } = useParams()
  const [test, setTest] = useState(null)
  const [loading, setLoading] = useState(false)
  const [startTest, setStartTest] = useState(false)
  const [showSolutions, setShowSolutions] = useState(false)
  const [error, setError] = useState(false)
  const [alreadyStarted, setAlreadyStarted] = useState(false)
  const [circularContent, setCircularContent] = useState(null)
  const [page, setPage] = useState(0)
  const { width } = useWindowDimensions()

  const refCover = useRef(null)
  const splideRef = useRef(null)

  const getTest = useCallback(async (background = false) => {
    if (!background) {
      setLoading(true)
    }
    try {
      const test = await api.get(`/tests/${testId}`)
      setTest({ ...test })
    } catch (e) {
      setError(e)
      console.error(e)
    }
    if (!background) {
      setLoading(false)
    }
    return () => {
      if (submitTimeout) {
        clearTimeout(submitTimeout)
        submitTimeout = null
      }
    }
  }, [])

  useEffect(() => {

    const storage = localStorage.getItem("userTests")
    if (storage) {
      const data = JSON.parse(storage)
      if (data[testId]) {
        setAlreadyStarted(true)
      }
    }
    getTest()
  }, [])

  useEffect(() => {
    if (!test) {
      return
    }

    if (!startTest && !test?.user_test && test?.status === TestStatus.Public) {
      setPage(0)
    } else if (startTest) {
      setPage(1)
    } else if (!startTest && test?.user_test && !showSolutions) {
      setPage(2)
    } else if (!startTest && test?.user_test && showSolutions) {
      setPage(3)
    } else if (!startTest && !test?.user_test && test?.status === TestStatus.Completed) {
      cleanLocalStorage()
      setPage(4)
    }
  }, [startTest, test, showSolutions])

  useEffect(() => {
    if (splideRef && splideRef.current) {
      splideRef.current.splide.go(page)
    }
  }, [page, splideRef])

  const numberOfQuestions = useMemo(() => {
    if (!test) {
      return;
    }
    const { content } = test
    return content?.length ?? 0
  }, [test])

  const tags = useMemo(() => {
    if (!test) {
      return [];
    }
    const { content } = test
    const uniqueTags = {}
    for (const innerTest of content) {
      const { tags } = innerTest
      for (const tag of tags)
        if (!uniqueTags[tag.id]) {
          uniqueTags[tag.id] = tag
        }
    }

    return Object.values(uniqueTags)
  }, [test])

  const circularData = useMemo(() => {
    if (!test) return [
      {
        id: "correct",
        value: 0,
        rawValue: 0,
        color: 'var(--primary)',
        label: t("tests.correctAnswers")
      },
      {
        id: "wrong",
        value: 0,
        rawValue: 0,
        color: 'var(--secondary)',
        label: t("tests.wrongAnswers")
      },
      {
        id: "notAnswered",
        value: 0,
        rawValue: 0,
        color: '#B5B8BE',
        label: t("tests.notAnswered")
      },
    ]

    let correct = 0
    let wrong = 0
    let notAnswered = 0

    const successThreshold = test.success_threshold ?? 0
    if (test.user_test) {
      const { content: innerTests, user_test } = test
      for (const innerTest of innerTests) {
        const { testType } = innerTest
        switch (testType) {
          case TestType.SingleChoice:
          case TestType.MultipleChoice:

            const correctAnswers = innerTest.answers.filter(a => a.isCorrect).map(a => a.id)
            const wrongAnswers = innerTest.answers.filter(a => !a.isCorrect).map(a => a.id)
            const userAnswers = user_test.content.find(c => c.id === innerTest.id).responses.filter(r => r.value).map(r => r.id)
            console.log(userAnswers)

            for (const ua of userAnswers) {
              if (correctAnswers.includes(ua)) {
                console.log(ua, "correct")
                correct += 1
              } else if (wrongAnswers.includes(ua)) {
                console.log(ua, "wrong")
                wrong += 1
              }
            }

            if (correctAnswers.length > userAnswers.length) {
              notAnswered = correctAnswers.length - userAnswers.length
            }
            break;
          case TestType.TrueFalse: {
            const { answers } = innerTest

            const trueAnswers = answers.filter(a => a.value).map(a => a.id)
            const falseAnswers = answers.filter(a => !a.value).map(a => a.id)
            const userAnswers = user_test.content.find(c => c.id === innerTest.id).responses
            for (const ua of userAnswers) {
              if (ua.value === true) {
                if (trueAnswers.includes(ua.id)) {
                  correct += 1
                } else {
                  wrong += 1
                }
              } else if (ua.value === false) {
                if (falseAnswers.includes(ua.id)) {
                  correct += 1
                } else {
                  wrong += 1
                }
              } else if (ua.value === null) {
                notAnswered += 1
              }
            }
          } break
          case TestType.TextCompletion: {
            const { words } = innerTest
            for (let i = 0; i < words.length; i++) {
              if (words[i].hidden) {
                const { solutions } = words[i]
                const userAnswer = user_test.content
                  .find(c => (
                    c.id === innerTest.id
                  ))?.responses.find(r => (
                    r.index === i
                  ))
                if (userAnswer && !userAnswer.value) {
                  notAnswered += 1
                } else {
                  const isCorrect = solutions.includes(userAnswer?.value)
                  if (isCorrect) {
                    correct += 1
                  } else {
                    wrong += 1
                  }
                }
              }
            }
          } break
          default: console.error("Unsupported test type"); break
        }
        console.debug({ testType, correct, wrong, notAnswered })
      }

    }
    console.debug({ correct, wrong, notAnswered })
    const sum = correct + wrong + notAnswered

    const pc = Math.round(((correct) / sum * 100) * 100) / 100
    const pw = Math.round(((wrong) / sum * 100) * 100) / 100
    const pna = Math.round(((notAnswered) / sum * 100) * 100) / 100

    if ((correct + wrong) === 0) {
      setCircularContent({
        label: t("tests.notCompleted"), style: {
          fontSize: "1.125rem",
          fontWeight: 800,
          fill: "#B5B8BE"
        }
      })
    } else if (correct > 0 && correct >= successThreshold) {
      setCircularContent({
        label: t("tests.passed"), style: {
          fontSize: "1.5rem",
          fontWeight: 800,
          fill: "var(--primary)"
        }
      })
    } else {
      setCircularContent({
        label: t("tests.failed"), style: {
          fontSize: "1.5rem",
          fontWeight: 800,
          fill: "var(--secondary)"
        }
      })
    }

    return [
      {
        id: "correct",
        value: pc,
        rawValue: correct,
        color: 'var(--primary)',
        label: t("tests.correctAnswers")
      },
      {
        id: "wrong",
        value: pw,
        rawValue: wrong,
        color: 'var(--secondary)',
        label: t("tests.wrongAnswers")
      },
      {
        id: "notAnswered",
        value: pna,
        rawValue: notAnswered,
        color: '#B5B8BE',
        label: t("tests.notAnswered")
      },
    ]
  }, [test])

  const cleanLocalStorage = () => {
    const storage = localStorage.getItem("userTests")
    const storageIndex = localStorage.getItem("userTestIndexes")
    if (storage) {
      const ut = JSON.parse(storage)
      if (ut[testId]) {
        delete ut[testId]
        localStorage.setItem('userTests', JSON.stringify(ut));
      }
    }

    if (storageIndex) {
      const ut = JSON.parse(storageIndex)
      if (ut[testId]) {
        delete ut[testId]
        localStorage.setItem('userTestIndexes', JSON.stringify(ut));
      }
    }
  }

  const onRetry = useCallback(() => {
    cleanLocalStorage()
    setStartTest(true)
  }, [testId])

  const onSubmit = useCallback(() => {
    submitTimeout = setTimeout(() => {
      setStartTest(false)
      setShowSolutions(false)
      setError(false)
      setAlreadyStarted(false)
      getTest(true)
      submitTimeout = null
    }, 2000)
  }, [getTest])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>{test?.name ?? "Test"}</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>

            <div className={styles.testContainer}>
              {
                loading === false && error &&
                <div
                  ref={refCover}
                  className={styles.initialCover} style={{ gap: "1rem", minHeight: "256px" }}>
                  <ErrorIcon className={styles.icon} style={{ color: "var(--secondary)" }} />
                  <div className={styles.name}>{t("errors.somethingWentWrong")}</div>
                  <div className={styles.description}>{t("errors.invalidTest")}</div>
                </div>
              }

              {
                loading &&
                <div ref={refCover} className={styles.initialCover}>
                  <div className={styles.top} style={{ alignItems: "center" }}>
                    <Skeleton width={width > 540 ? "256px" : "128px"} height="1.625rem" borderRadius="12px" />
                    <Skeleton width={width > 540 ? "512px" : "256px"} height="1.25rem" borderRadius="12px" />
                  </div>
                  <div className={styles.middle}>
                    <div className={styles.card} style={{ gap: ".5rem" }}>
                      <Skeleton width="72px" height=".875rem" borderRadius="12px" />
                      <Skeleton width="112px" height="1rem" borderRadius="12px" />
                    </div>
                    <div className={styles.card} style={{ gap: ".5rem" }}>
                      <Skeleton width="72px" height=".875rem" borderRadius="12px" />
                      <div className={styles.tags}>
                        <Each
                          of={[130, 140, 86]}
                          render={(tagWidth) => (
                            <Skeleton type="rect" width={`${tagWidth}px`} height="24px" borderRadius="8px" />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.button}>
                    <Skeleton type="rect" width={`256px`} height="2rem" borderRadius="10rem" />
                  </div>
                </div>
              }
              {
                !loading && !error &&
                <Splide className={styles.splide} aria-label="..." ref={splideRef} options={{
                  rewind: false,
                  drag: false,
                  autoplay: false,
                  pagination: false,
                }}>
                  <SplideSlide className={styles.slide}>
                    <div
                      className={styles.initialCover}>
                      <div className={styles.top}>
                        <div className={styles.name}>{test?.name}</div>
                        <div className={styles.description}>{test?.description}</div>
                      </div>
                      <div className={styles.middle}>
                        <div className={styles.card}>
                          <div className={styles.label}>{t("tests.requests")}</div>
                          <div className={styles.requests}><QuestionsIcon style={{ marginRight: "4px" }} /> {numberOfQuestions} {(numberOfQuestions === 0 || numberOfQuestions > 1) ? t("tests.requests") : t("tests.request")}</div>
                        </div>
                        {
                          tags.length > 0 &&
                          <div className={styles.card}>
                            <div className={styles.label}>{t("tests.tags")}</div>
                            <div className={styles.tags}>
                              {
                                tags && tags.length > 0 &&
                                <Each
                                  of={tags}
                                  render={(tag) => (
                                    <Tag tag={tag} />
                                  )}
                                />
                              }
                            </div>
                          </div>
                        }
                      </div>
                      <div className={styles.button}>
                        <Button
                          fullWidth
                          accentColor={"var(--tertiary)"}
                          style={{ width: "256px" }}
                          onClick={() => { setStartTest(true) }}
                        >
                          {
                            alreadyStarted &&
                            t("tests.continue").toUpperCase()
                          }
                          {
                            !alreadyStarted &&
                            t("tests.start").toUpperCase()
                          }
                        </Button>
                      </div>
                    </div>
                  </SplideSlide>

                  <SplideSlide className={styles.slide}>
                    {
                      page === 1 &&
                      <TestForm
                        test={test}
                        loading={loading}
                        onSubmit={onSubmit}
                      />
                    }
                  </SplideSlide>
                  <SplideSlide className={styles.slide}>
                    {
                      page === 2 &&
                      <div className={styles.completedTest}>
                        <div className={styles.top}>
                          <div className={styles.name}>{test?.name}</div>
                          <div className={styles.description}>{test?.description}</div>
                        </div>
                        <div className={styles.middle}>
                          <div className={styles.circularChartColumn}>
                            <CircularProgess content={circularContent ?? []} data={circularData.filter(d => d.id !== "notAnswered")}></CircularProgess>
                            <div className={styles.chartLabels}>
                              {circularData.map(data => {
                                return (
                                  <div className={styles.chartCell} key={data.label}>
                                    <div className={styles.chartRow}>
                                      <div className={styles.chartColumn}>
                                        <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                                      </div>
                                      <div className={styles.chartColumn}>
                                        <div className={styles.chartDataTitle}>{data.rawValue}</div>
                                        <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className={styles.buttons}>
                          {
                            test?.can_be_retried && test?.status === TestStatus.Public &&
                            <div className={styles.button}>
                              <Button
                                fullWidth
                                accentColor={"var(--sf-orange)"}
                                onClick={onRetry}
                              >
                                {t("tests.retry").toUpperCase()}
                                <RetryIcon className={styles.icon} />
                              </Button>

                            </div>
                          }
                          {
                            (test?.correction_type === CorrectionType.Errors || test?.correction_type === CorrectionType.Solutions) &&
                            <div className={styles.button}>
                              <Button
                                fullWidth
                                accentColor={"var(--tertiary)"}
                                onClick={() => setShowSolutions(true)}
                              >
                                {t("tests.showSolutions").toUpperCase()}
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    }
                  </SplideSlide>
                  <SplideSlide className={styles.slide}>
                    {
                      page === 3 &&
                      <TestResult
                        test={test}
                        onBack={() => {
                          setShowSolutions(false)
                        }}
                      />
                    }
                  </SplideSlide>
                  <SplideSlide className={styles.slide}>
                    {
                      page === 4 &&
                      <div className={styles.initialCover}>
                        <div className={styles.top}>
                          <div className={styles.name}>{test?.name}</div>
                          <div className={styles.description}>{test?.description}</div>
                        </div>
                        <div className={styles.middle}>
                          <div className={styles.card}>
                            <div className={styles.label}>{t("tests.requests")}</div>
                            <div className={styles.requests}><QuestionsIcon style={{ marginRight: "4px" }} /> {numberOfQuestions} {(numberOfQuestions === 0 || numberOfQuestions > 1) ? t("tests.requests") : t("tests.request")}</div>
                          </div>
                          {
                            tags.length > 0 &&
                            <div className={styles.card}>
                              <div className={styles.label}>{t("tests.tags")}</div>
                              <div className={styles.tags}>
                                {
                                  tags && tags.length > 0 &&
                                  <Each
                                    of={tags}
                                    render={(tag) => (
                                      <Tag tag={tag} />
                                    )}
                                  />
                                }
                              </div>
                            </div>
                          }
                        </div>
                        <div className={styles.expiredContainer}>
                          <div className={styles.expired}>
                            {t("tests.expired", { when: calcLastUpdate(test.expires_at, false) })}
                          </div>
                          <div className={styles.button}>
                            <Button
                              disabled
                              fullWidth
                              accentColor={"var(--tertiary)"}
                              style={{ width: "256px" }}
                              onClick={() => { setStartTest(true) }}
                            >
                              {t("tests.start").toUpperCase()}
                            </Button>
                          </div>
                        </div>
                      </div>
                    }
                  </SplideSlide>
                </Splide>
              }
            </div>

          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}


export default Test
