import { ReactComponent as BookIllustration } from "../assets/images/illustrations/il-book-work.svg"
import { ReactComponent as BooksIllustration } from "../assets/images/illustrations/il-books.svg"
import { ReactComponent as Contact } from "../assets/images/illustrations/il-contact.svg"
import { ReactComponent as ExpertIllustration } from "../assets/images/illustrations/il-expert.svg"
import { ReactComponent as LinkedinBadge } from "../assets/images/illustrations/3D/startup.svg"
import { ReactComponent as Marketing } from "../assets/images/illustrations/il-marketing.svg"
import { ReactComponent as MediaIllustration } from "../assets/images/illustrations/il-media.svg"
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg"
import { ReactComponent as SocialBadge } from "../assets/images/illustrations/3D/social.svg"
import { ReactComponent as VideosIllustration } from "../assets/images/illustrations/il-videos.svg"
import { ReactComponent as Wave } from "../assets/images/illustrations/il-wave.svg"
import { ReactComponent as SFLogo } from "../assets/images/velv.svg"

import Picture3D from "../assets/images/illustrations/3D/picture.png"
import Notebook3D from "../assets/images/illustrations/3D/notebook.png"
import Computer3D from "../assets/images/illustrations/3D/computer.png"
import Book3D from "../assets/images/illustrations/3D/book.png"
import Flag3D from "../assets/images/illustrations/3D/flag.png"
import {ReactComponent as Star3D} from "../assets/images/illustrations/3D/star.svg"
import IA3D from "../assets/images/illustrations/3D/ia.png"
import Survey3D from "../assets/images/illustrations/3D/survey.webp"
import Explorer3D from "../assets/images/illustrations/3D/explorer.png"
import Contents3D from "../assets/images/illustrations/3D/contents.webp"
import Psyco3D from "../assets/images/illustrations/3D/psyco.webp"

import Button from "../components/Button"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Landing.module.css"


import { useEffect, useLayoutEffect, useState } from "react"
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { Each } from "../common/Each"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import FAQAccordions from "../components/FAQAccordions"
import ReviewsCarousel from "../components/ReviewsCarousel"
import Card from "../components/cards/Card"
import typo from "../typography.module.css"

const Landing = () => {

    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const [reviews, setReviews] = useState(null)
    // eslint-disable-next-line no-multi-str
    const backgroundImage = 'radial-gradient(circle at 45% 450px, rgba(76, 180, 255, 0.14) 1%, transparent 6%),\
                             radial-gradient(circle at 55% 450px, rgba(76, 180, 255, 0.14) 1%, transparent 6%),\
                             radial-gradient(circle at 100% 250px, rgba(47, 189, 199, .25) 1%, transparent 3%),\
                             radial-gradient(circle at 110% 750px, rgba(207, 85, 255, .25) 1%, transparent 5%)'

    const problems = [
        {
            number: '01',
            title: 'Scarsa educazione al percorso di carriera',
            content: 'Non si educano i giovani a conoscere sé stessi per allineare la loro personalità col percorso di carriera in cui potranno avere maggior successo ed essere più soddisfatti.',
            solutionTitle: 'Psicologi del lavoro e Test Psicometrico',
            solutionContent: 'Velv ti permette di seguire un percorso innovativo con l’ausilio di psicologi del lavoro e un test psicometrico per identificare le carriere in cui potresti avere più successo in base alla tua personalità.',
            solutionImage: <img src={Psyco3D} alt="" className={styles.solutionImage}/>,
            color: "76, 180, 255",
            backColor: "91, 214, 255"
        },
        {
            number: '02',
            title: 'Poco orientamento su economia e finanza',
            content: 'Rispetto all\'estero, ci sono poche valide opportunità di orientamento alle carriere del mondo economico e finanziario.',
            solutionTitle: 'Contenuti di orientamento',
            solutionContent: 'Accesso a contenuti di orientamento e approfondimento sulle carriere nel settore economico-finanziarie, curati dalla più grande community italiana di economia e finanza.',
            solutionImage: <img src={Contents3D} alt="" className={styles.solutionImage}/>,
            color: "252, 255, 76",
            backColor: "47, 199, 160"
        },
        {
            number: '03',
            title: 'Molta teoria e poca pratica ',
            content: 'Il sistema educativo dà troppo peso alla teoria e poco alla pratica, creando un divario tra ciò che gli studenti imparano e le competenze richieste nel mondo lavorativo.',
            solutionTitle: 'Percorsi formativi completi',
            solutionContent: 'Percorsi formativi creati con il supporto di aziende e professionisti, per darti le competenze necessarie a inserirti con successo nel mercato del lavoro.',
            solutionImage: <img src={Explorer3D} alt="" className={styles.solutionImage}/>,
            color: "249, 76, 255",
            backColor: "94, 155, 255"
        }

    ]

    const faqs = [
        {
            "name": "Che cos'è Velv?",
            "description": ""
        },
        {
            "name": "Cos'è una Masterclass?",
            "description": ""
        },
        {
            "name": "Come funziona una Masterclass?",
            "description": ""
        },
        {
            "name": "Dove posso seguire le lezioni?",
            "description": ""
        }
    ]

    useEffect(() => {
        const getReviews = async () => {
            try {
                let reviews = await api.get(`/reviews`)
                setReviews(reviews)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!reviews) {
            getReviews()
        }
    }, [reviews])


    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <HeaderFooterLayout hideFooter style={{ backgroundImage: backgroundImage }}>
            <div className={styles.container}>
                <div className={styles.section} id={styles.main}>
                    <div className={styles.sectionInner} style={{ paddingBottom: 0 }}>
                        <div className={styles.banner}>
                            <img src={Book3D} className={styles.bookIllustration} alt=""/>
                            <img src={Picture3D} className={styles.eduIllustration} alt="" />
                            <div className={styles.title}>
                                <div className={styles.titleRow}>
                                    <div>Conosci</div>
                                    <div className={styles.underlined}>
                                        te stesso
                                        <Wave className={styles.wave} style={{
                                            clipPath: 'polygon(0 0, 85% 0%, 94% 100%, 0 100%)',
                                            'WebkitClipPath': 'polygon(0 0, 85% 0%, 94% 100%, 0 100%)'
                                        }} />
                                    </div>
                                    <div>e costruisci</div>
                                </div>
                                <div className={styles.titleRow}>
                                    <div>la</div>
                                    <div className={styles.underlined}>
                                        carriera
                                        <Wave className={styles.wave} style={{
                                            clipPath: 'polygon(0 0, 75% 0%, 84% 100%, 0 100%)',
                                            'WebkitClipPath': 'polygon(0 0, 75% 0%, 79% 100%, 0 100%)'
                                        }} />
                                    </div>
                                    <div>migliore</div>
                                    <div className={styles.underlined}>
                                        per te
                                        <Wave className={styles.wave} style={{
                                            clipPath: 'polygon(0 0, 61% 0%, 69% 100%, 0 100%)',
                                            'WebkitClipPath': 'polygon(0 0, 61% 0%, 69% 100%, 0 100%)'
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className={[styles.subtitle, typo.body].join(' ')}>
                                Velv è la prima piattaforma in Italia che sulla base della <b>tua personalità</b> individua le carriere economiche finanziarie <b>più adatte a te</b> e ti dà le <b>competenze</b> per ottenerle.                            </div>
                            <Button
                                appearance="gradient"
                                onClick={() => { navigate('/login') }}
                                additionalClass={styles.actionButton}>INIZIA ORA</Button>
                        </div>
                        <div className={styles.socialProof}>
                            <div className={styles.socialProofBox}>
                                <SocialBadge className={styles.socialBadge}/>
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    +1,9 milioni
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    I membri della nostra community
                                </div>
                            </div>
                            <div className={styles.socialProofBox}>
                                <LinkedinBadge className={styles.linkedinBadge}/>
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    Miglior Startup
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    Top 10 LindedIn Startups - Italia
                                </div>
                            </div>
                            <div className={styles.socialProofBox}>
                                <Star3D className={styles.trustpilotBadge}/>
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    4.8 su 5.0
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    La media delle nostre recensioni su Trustpilot
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.section} style={{ background: 'linear-gradient(0deg, var(--background-color) 0%, var(--background-color) 70%, transparent 100%)' }}>
                    <div className={styles.sectionInner} style={{ background: 'transparent', alignItems: 'center' }}>
                        <div className={styles.playerWrapper}>
                            {/* <VideoBottomLeft className={styles.videoBottomLeft} />
                            <VideoTopRight className={styles.videoTopRight} /> */}
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=dTor1Gd4WSw"
                                width="100%"
                                height="100%"
                                className={styles.player}
                                playIcon={<PlayIcon style={{transform: 'scale(2)'}}/>}
                                light={"https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/thumbnails/cover-landing-page.png"}
                                config={
                                    {
                                        youtube: {
                                            playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                        }
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.section} id={styles.problems}>
                    <div className={styles.sectionInner} style={{ background: 'transparent', alignItems: 'center' }}>
                        <div className={`${typo.title} ${styles.sectionTitle}`}>
                            Perchè tutti dovrebbero usare VELV?
                        </div>
                        <div className={typo.body} style={{ textAlign: 'center' }}>
                            In Italia abbiamo <span style={{ color: 'var(--secondary)', fontWeight: 700 }}>tre grossi problemi</span>
                        </div>
                        <div className={styles.problems}>
                            <Each of={problems} render={(problem) => {
                                return (
                                    <div className={styles.problem}>
                                        <div className={styles.problemContent}>
                                            <div className={styles.problemNumber}>
                                                {problem.number}
                                                <div className={styles.problemLabel}>PROBLEMA</div>
                                            </div>
                                            <div className={styles.problemTitle}>
                                                {problem.title}
                                            </div>
                                            <div className={[styles.problemDescription, typo.body].join(' ')}>
                                                {problem.content}
                                            </div>
                                        </div>
                                        <div className={styles.solutionContent}>
                                            <Card style={{
                                                width: width > 768 ? '480px' : 'unset',
                                                flexDirection: 'column',
                                                padding: '2rem 1rem',
                                                gap: '1rem',
                                                zIndex: 1
                                            }}>
                                                <div className={styles.solutionTitle}>
                                                    {problem.solutionTitle}
                                                    {problem.solutionImage}
                                                </div>
                                                <div className={[styles.solutionDescription, typo.body].join(' ')}>
                                                    {problem.solutionContent}
                                                </div>
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '-70%',
                                                    left: '-50%',
                                                    background: `radial-gradient(rgba(${problem.color}, 20%) 0, transparent 50%)`,
                                                    height: '400px',
                                                    width: '400px'
                                                }} />
                                            </Card>
                                            <div className={styles.radialGradient} style={{ background: `radial-gradient(rgba(${problem.backColor}, 40%) 0%, transparent 50%)`}}></div>
                                        </div>
                                    </div>
                                )
                            }} />
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.platform}>
                            <div className={styles.inner}>
                                <div className={styles.innerTitle}>
                                    Come funziona la piattaforma?
                                </div>
                                <div className={[styles.innerDescription, typo.body].join(' ')}>
                                    All'ingresso ci sarà un primo questionario per conoscere i tuoi interessi e le tue aspirazioni di carriera. Una volta dentro, avrai accesso a contenuti dedicati alle principali carriere nel settore economico-finanziario, oltre a corsi che ti prepareranno a entrarvi con successo.<br></br><br></br>Vuoi scoprire in quali carriere potrai avere più successo in base alla tua personalità?<br></br>Scorri per scoprire il funzionamento del nostro test psicometrico.
                                </div>
                                <Button
                                    additionalClass={styles.actionButton}
                                    style={{fontSize: '1.25rem'}}
                                    onClick={() => { navigate('/login') }}
                                >
                                    INIZIA ORA
                                </Button>
                            </div>
                            <div className={styles.screenshot} style={{transform: scrollPosition > 2500 ? `translateY(-${(scrollPosition)-(2500)}px)`: ''}}>
                                <img src="./images/platform.png" alt="platform" className={styles.platformScreenshot} />
                                <img src="./images/courses.png" alt="platform" className={styles.platformScreenshot2} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.platform}>
                            <div className={styles.cards}>
                                <div className={styles.card} style={{top: 0, right:'10%'}}>
                                    <img src={Survey3D} alt="" style={{display:'flex', objectFit: 'contain', height: '140px', width: '140px'}}/>
                                    <div style={{
                                                    position: 'absolute',
                                                    bottom: '-70%',
                                                    left: '-50%',
                                                    background: `radial-gradient(rgba(var(--primary-rgb), 20%) 0, transparent 50%)`,
                                                    height: '400px',
                                                    width: '400px'
                                                }} />
                                    <div style={{
                                                    position: 'absolute',
                                                    top: '-70%',
                                                    right: '-50%',
                                                    background: `radial-gradient(rgba(207, 85, 255, .25) 0, transparent 50%)`,
                                                    height: '400px',
                                                    width: '400px'
                                                }} />
                                                <div className={typo.subtitle}>Un Test<br></br>Psicometrico</div>
                                </div>
                                <div className={styles.card} style={{bottom: 0, left: '10%', width: '300px'}}>
                                    <img src={IA3D} alt="" style={{display:'flex', objectFit: 'contain', height: '140px', width: '140px'}}/>
                                    <div style={{
                                                    position: 'absolute',
                                                    bottom: '-70%',
                                                    left: '-0%',
                                                    background: `radial-gradient(rgba(var(--primary-rgb), 20%) 0, transparent 50%)`,
                                                    height: '400px',
                                                    width: '400px'
                                                }} />
                                    <div style={{
                                                    position: 'absolute',
                                                    top: '-70%',
                                                    right: '-0%',
                                                    background: `radial-gradient(rgba(var(--sf-yellow-rgb), 20%) 0, transparent 50%)`,
                                                    height: '400px',
                                                    width: '400px'
                                                }} />
                                                                                                <div className={typo.subtitle}>Validato dall' IA</div>

                                </div>
                            </div>
                            <div className={styles.inner}>
                                <div className={styles.innerTitle}>
                                    Come funziona il test psicometrico?
                                </div>
                                <div className={[styles.innerDescription, typo.body].join(' ')}>
                                Grazie al nostro test psicometrico scientificamente validato e sviluppato con il supporto dell'intelligenza artificiale, potrai ottenere un quadro chiaro dei tuoi punti di forza e delle carriere più adatte alla tua personalità. Potrai inoltre avvalerti dell'aiuto di psicologi del lavoro specializzati per analizzare i risultati del test e
                                trasformarli in un percorso concreto per il tuo successo professionale.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {reviews && reviews.length > 0 &&
                    <div className={`${styles.section} ${styles.reviews}`}>
                        <div className={[styles.sectionInner, styles.reviewsInner].join(' ')} style={{ flexDirection: 'column' }}>
                            <Marketing className={styles.marketingIllustration} />
                            <div className={`${typo.title} ${styles.sectionTitle}`}>
                                Cosa dice chi ha provato VELV
                            </div>
                            <div className={typo.body} style={{ textAlign: 'center' }}>
                                Le recensioni dei nostri utenti.
                            </div>
                            <div className={styles.reviewsCards}>
                                <ReviewsCarousel reviews={reviews} style={{maxWidth: 'none'}}/>
                            </div>
                        </div>
                    </div>
                }

                <div className={[styles.section, styles.who].join(' ')}>
                    <div className={styles.sectionInner}>
                        <img src={Computer3D} className={styles.newsIllustration} alt=""/>
                        <div className={typo.title}>
                            Chi Siamo?
                        </div>
                        <div className={[styles.whoContent, typo.body].join(' ')}>
                            VELV nasce per portare in Italia quello che non hai mai imparato a scuola: come scegliere il lavoro migliore per te in base alla tua personalità. E lo fa al fianco di Starting Finance, la più grande community Italiana per giovani del mondo economico e finanziario.
                        </div>
                        <img src={Flag3D} className={styles.mediaIllustration} alt=""/>
                    </div>
                </div>

                <div className={styles.section} style={{ backgroundColor: "var(--background-secondary-color)", textAlign: 'center' }}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>
                            FAQ
                        </div>
                        <FAQAccordions faq={faqs} />
                    </div>
                </div>

                <div className={styles.section} style={{ backgroundColor: "var(--background-secondary-color)" }}>
                    <div className={styles.sectionInner}>
                        <div className={styles.contact}>
                            <div className={[typo.title, styles.sectionTitle].join(' ')}>
                                Hai delle domande?
                            </div>
                            <div className={typo.body} style={{ textAlign: 'center', paddingBottom: '2rem' }}>
                                Contattaci al nostro indirizzo email o tramite whatsapp!
                            </div>
                            <Button
                                style={{ padding: '1rem 2rem', fontSize: '1.15rem', zIndex: 1 }}
                                accentColor="var(--tertiary)">
                                CONTATTACI
                            </Button>
                            <Contact className={styles.contactIllustration} />
                        </div>
                    </div>
                </div>

                <div className={styles.section} style={{ backgroundColor: 'var(--text-color)' }}>
                    <div className={styles.sectionInner}>
                        <div className={styles.footer}>
                            <div className={styles.footerWho}>
                                <SFLogo className={styles.logo} />
                                <div className={styles.footerWhoContent}>
                                    VELV nasce per portare in Italia quello che non hai mai imparato a scuola: come scegliere il lavoro migliore per te in base alla tua personalità. E lo fa al fianco di Starting Finance, la più grande community Italiana per giovani del mondo economico e finanziario.
                                </div>
                            </div>
                            <div className={styles.footerLinks}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    LINK RAPIDI
                                </div>
                                <div className={styles.links}>
                                    <a>Masterclass</a>
                                    <a>Approfondimenti</a>
                                    <a>Merchandising</a>
                                    <a>Recensioni</a>
                                    <a>Rimborsi</a>
                                </div>
                            </div>
                            <div className={styles.footerContact}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    CONTATTI
                                </div>
                                <div className={styles.links}>
                                    <a>Invia un'email</a>
                                    <a>Contatta il supporto</a>
                                </div>
                            </div>
                            <div className={styles.footerSocial}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    SEGUICI
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </HeaderFooterLayout >
    )

}

export default Landing