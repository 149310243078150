import { DailyVideo, useDaily, useDevices, useLocalSessionId, useMeetingSessionState, useMeetingState, useParticipantIds, useParticipantProperty, useScreenShare } from "@daily-co/daily-react"
import { useContext, useEffect, useRef, useState } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import api from "../../api"
import { ReactComponent as PinIcon } from "../../assets/images/icons/ic-pin.svg"
import { darkPalette } from "../../common/constants"
import { Each } from "../../common/Each"
import MainContext from "../../common/MainContext"
import Button from "../Button"
import DailyChat from "./DailyChat"
import DailyControls from "./DailyControls"
import DailyParticipant from "./DailyParticipant"
import styles from "./DailyRoom.module.css"

const DailyRoom = ({ lesson_id }) => {

    const context = useContext(MainContext)
    const call = useDaily()
    const devices = useDevices()
    const meetingState = useMeetingState()
    const localSessionId = useLocalSessionId()
    const participants = useParticipantIds({ filter: 'remote' })
    // const localParticipant = useParticipantProperty(localSessionId, ['user_name', 'userData', 'tracks'])
    const { isSharingScreen, screens } = useScreenShare()

    const ownersIds = useParticipantIds({ filter: (p) => p.owner })
    const ownerTracks = useParticipantProperty(ownersIds[0], 'tracks')

    const [spotlighted, setSpotlighted] = useState(null)
    const [showParticipants, setShowPartecipants] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [chat, setChat] = useState(false)
    const [unread, setUnread] = useState(0)

    const fullScreenHandle = useFullScreenHandle()
    const whiteboardRef = useRef(null)
    const presentationRef = useRef(null)


    const spotlightedParticipant = useParticipantProperty(spotlighted?.sessionId, ['tracks'])
    const [accessState, setAccessState] = useState('unknow')

    const { data: sessionData } = useMeetingSessionState()

    useEffect(() => {

        const addUserParticipantId = async () => {
            try {
                await api.put(`/lessons/${lesson_id}/participant`, { participant_id: localSessionId })
            }
            catch (e) {
                console.error(e)
            }
        }

        if (localSessionId) {
            addUserParticipantId()
        }

    }, [localSessionId])

    useEffect(() => {
        if (sessionData?.spotlighted) {
            setSpotlighted(sessionData.spotlighted)
        }
        else {
            if (ownersIds && ownersIds.length > 0) {
                let whiteboard = !!ownerTracks?.whiteboard
                let presentation = !!ownerTracks?.presentation
                let owner = ownersIds[0]
                let ownerScreenShare = screens.find(s => s.session_id === owner)

                setSpotlighted({
                    sessionId: owner,
                    isScreenShare: !!ownerScreenShare,
                    whiteboard: whiteboard,
                    presentation: presentation
                })
            }

            //Se non c'è un owner, mostra lo stream locale
            if (!ownersIds[0] && localSessionId) {
                setSpotlighted({
                    sessionId: localSessionId,
                    isScreenShare: isSharingScreen,
                    whiteboard: false,
                    presentation: false
                })
            }
        }
    }, [ownersIds, screens, localSessionId, ownerTracks, sessionData])

    useEffect(() => {
        if (devices.cameras.length > 0) {
            if (!devices.currentCam) {
                devices.setCamera(devices.cameras[0].device.deviceId)
                call.setLocalVideo(false)
            }
        }
        if (devices.speakers.length > 0) {
            if (!devices.currentSpeaker) {
                devices.setSpeaker(devices.speakers[0].device.deviceId)
                call.setLocalAudio(false)
            }
        }
        if (devices.microphones.length > 0) {
            if (!devices.currentMic) {
                devices.setMicrophone(devices.microphones[0].device.deviceId)
                call.setLocalAudio(false)
            }
        }
    }, [devices])

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .catch(error => {
                console.error(error)
            })
        try {
            call.setUserName(`${context.user?.name} ${context.user.surname}`)
            call.setUserData({ externalUser: context.user })
        }
        catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        if (spotlighted) {
            if (spotlighted.whiteboard) {
                let videoElm = document.querySelector('#whiteboard')
                if (videoElm && ownerTracks?.whiteboard && ownerTracks?.whiteboard?.state === 'playable') {
                    let stream = new MediaStream([ownerTracks.whiteboard.track])
                    videoElm.srcObject = stream
                }
            }
            if (spotlighted.presentation) {
                let videoElm = document.querySelector('#presentation')
                if (videoElm && ownerTracks?.presentation && ownerTracks?.presentation?.state === 'playable') {
                    let stream = new MediaStream([ownerTracks.presentation.track])
                    videoElm.srcObject = stream
                }
            }
        }
    }, [spotlighted, ownerTracks])

    useEffect(() => {
        if (call) {
            const accessState = call.accessState()
            console.debug(`Call State:`, meetingState)
            setAccessState(accessState.access?.level ?? 'unknow')
            const userInfo = { id: context.user.id, name: `${context.user.name} ${context.user.surname}`, picture: context.user.picture }
            if (accessState.access.level === 'lobby' && ['joined-meeting', 'new'].includes(meetingState)) {
                console.debug("Requesting Access")
                call.requestAccess({ level: 'full', name: `${context.user.name} ${context.user.surname}` })
            }
        }

        if (meetingState === 'error') {
            setAccessState('error')
        }
    }, [call, meetingState])

    const onFullScreenChange = (fullScreenState) => {
        setFullScreen(fullScreenState)
    }

    return (
        <FullScreen handle={fullScreenHandle} onChange={onFullScreenChange}>
            <div className={styles.container}>
                <div className={`${styles.main} ${fullScreen ? styles.fullscreen : ''}`}>
                    <div className={styles.spotlighted}>
                        {spotlighted && !spotlighted.whiteboard && !spotlighted.presentation &&

                            <DailyVideo
                                automirror={spotlighted.sessionId === localSessionId}
                                type={spotlighted.isScreenShare ? 'screenVideo' : 'video'}
                                sessionId={spotlighted.sessionId}
                                style={{
                                    borderRadius: '12px',
                                    backgroundColor: 'black',
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        }
                        {spotlighted && spotlighted.whiteboard && !spotlighted.presentation &&
                            <video autoPlay id="whiteboard" ref={whiteboardRef} style={{ borderRadius: '12px', display: 'flex', width: '100%', height: '100%', backgroundColor: 'white' }} />
                        }
                        {spotlighted && spotlighted.presentation && !spotlighted.whiteboard &&
                            <video autoPlay id="presentation" ref={presentationRef} style={{ borderRadius: '12px', display: 'flex', width: '100%', height: '100%', backgroundColor: 'white' }} />
                        }

                        {sessionData?.spotlighted?.sessionId === spotlighted?.sessionId &&
                            <div className={styles.pinBadge}>
                                <PinIcon />
                                Fissato
                            </div>
                        }

                        {call.accessState().access.level !== 'full' &&
                            <div className={styles.overlay}>
                                {accessState === 'lobby' &&
                                    <div>
                                        In attesa che il docente ti ammetta alla lezione...
                                    </div>
                                }
                                {accessState === 'error' &&
                                    <div>
                                        Non sei più all'interno della lezione.
                                        <Button onClick={() => { call.join() }}>RIENTRA</Button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <DailyChat open={chat} unread={{ state: unread, setState: setUnread }} />
                </div>
                <div className={`${styles.participants} ${!showParticipants ? styles.close : ''}`}>
                    <div className={styles.participantsTrack}>
                        <DailyParticipant
                            isLocal={true}
                            session_id={localSessionId}
                            onClick={(isScreenShare) => {
                                setSpotlighted({
                                    sessionId: localSessionId,
                                    isScreenShare: isScreenShare
                                })
                            }} />
                        <Each of={participants} render={(p, index) => {
                            const colorIndex = (index) % darkPalette.length
                            const color = darkPalette[colorIndex]
                            return (
                                <>
                                    <DailyParticipant
                                        style={{ backgroundColor: color }}
                                        session_id={p}
                                        onClick={(isScreenShare, whiteboard, presentation) => {
                                            setSpotlighted({
                                                sessionId: p,
                                                isScreenShare: isScreenShare,
                                                whiteboard: whiteboard,
                                                presentation: presentation
                                            })
                                        }} />
                                </>
                            )
                        }} />
                    </div>
                </div>


                <DailyControls
                    localSessionId={localSessionId}
                    showParticipants={{ state: showParticipants, setState: setShowPartecipants }}
                    fullScreen={{ state: fullScreen, setState: setFullScreen, handle: fullScreenHandle }}
                    chat={{ state: chat, setState: setChat, unread: unread, setUnread: setUnread }}
                />
            </div>
        </FullScreen>
    )
}

export default DailyRoom
