import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as FlagIcon } from "../../assets/images/icons/ic-flag.svg"
import { ReactComponent as LessonIcon } from "../../assets/images/icons/ic-lessons-inactive.svg"
import { ReactComponent as MoneyBagIcon } from "../../assets/images/icons/ic-moneybag.svg"
import { ReactComponent as UserQuestionIcon } from "../../assets/images/icons/ic-person-question.svg"
import { ReactComponent as ReloadIcon } from "../../assets/images/icons/ic-reload.svg"
import Card from "../cards/Card"
import styles from "./SurveyCard.module.css"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"

const SurveyCard = () => {

    const { width } = useWindowDimensions()

    const [survey, setSurvey] = useState(null)
    const [salary, setSalary] = useState(null)
    const [workHour, setWorkHour] = useState(null)
    const [leading, setLeading] = useState(null)
    const [training, setTraining] = useState(null)
    const [career, setCareer] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        const getCareer = async (careerId) => {
            try {
                const career = await api.get(`/careers/${careerId}`)
                console.debug(career)
                setCareer(career)

            }
            catch (e) {
                console.error(e)
            }
        }

        const getSurvey = async () => {
            try {
                const survey = await api.get("/user/survey")
                setSurvey(survey)
                setSalary(survey.content.find(q => q.id === 3).answer.value)
                setWorkHour(survey.content.find(q => q.id === 6).answer.value)
                setLeading(survey.content.find(q => q.id === 7).answer.value)
                setTraining(survey.content.find(q => q.id === 9).answer.value)

                const careerId = survey.content.find(q => q.id === 10).answer.careerId
                if (careerId) {
                    getCareer(careerId)
                }
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!survey) {
            getSurvey()
        }
    }, [survey])

    return (
        <>
            {survey &&
                <Card style={{ padding: 0, flexDirection: 'column' }}>
                    <div className={styles.header}>
                        {
                            !career &&
                            <>
                                <div className={styles.headerIcon} style={{ backgroundColor: 'rgba(0,0,0, 10%)', color: 'white' }}>
                                    <UserQuestionIcon></UserQuestionIcon>
                                </div>
                                <div className={styles.careerName}>
                                    Non so ancora
                                </div>
                            </>
                        }
                        {
                            career &&
                            <>
                                <div className={styles.headerIcon} >
                                    <img src={career.thumbnail} alt="career icon" />
                                </div>
                                <div className={styles.careerName}>
                                    {career.name}
                                </div>
                            </>
                        }
                        <button
                            onClick={() => {
                                navigate("/survey")
                            }}
                            className={styles.retryButton}>
                            {width > 540 &&
                                <>Aggiorna</>
                            }
                            <ReloadIcon />
                        </button>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.questionBox}>
                            <div className={styles.questionTitle}>
                                Salario
                            </div>
                            <div className={styles.questionAnswer}>
                                <MoneyBagIcon className={styles.questionAnswerIcon} />
                                <div className={styles.questionAnswerLabel}>
                                    {salary}
                                </div>
                            </div>
                        </div>
                        <div className={styles.questionBox}>
                            <div className={styles.questionTitle}>
                                Orario
                            </div>
                            <div className={styles.questionAnswer}>
                                <ClockIcon className={styles.questionAnswerIcon} />
                                <div className={styles.questionAnswerLabel}>
                                    {workHour}
                                </div>
                            </div>
                        </div>
                        <div className={styles.questionBox}>
                            <div className={styles.questionTitle}>
                                Capacità di leading
                            </div>
                            <div className={styles.questionAnswer}>
                                <FlagIcon className={styles.questionAnswerIcon} />
                                <div className={styles.questionAnswerLabel}>
                                    {leading}
                                </div>
                            </div>
                        </div>
                        <div className={styles.questionBox}>
                            <div className={styles.questionTitle}>
                                Formazione
                            </div>
                            <div className={styles.questionAnswer}>
                                <LessonIcon className={styles.questionAnswerIcon} />
                                <div className={styles.questionAnswerLabel}>
                                    {training}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            }
        </>
    )

}
export default SurveyCard