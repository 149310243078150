import styles from "./Macro.module.css"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import api from "../api";
import ReactPlayer from "react-player";
import typo from "../typography.module.css"
import { Each } from "../common/Each";
import Button from "../components/Button"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { ReactComponent as FavoriteIcon } from "../assets/images/icons/ic-favorite.svg"
import { ReactComponent as RemoveFavoriteIcon } from "../assets/images/icons/ic-favorite-remove.svg"
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg"

import MacroSection from "../components/MacroSection";
import MainContext from "../common/MainContext";

const Macro = () => {
    const { slug } = useParams();
    const navigate = useNavigate()
    const [macro, setMacro] = useState(null)
    const [interest, setInterest] = useState(false)
    const context = useContext(MainContext)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        const getBySlug = async () => {
            try {
                let macro = await api.get(`/macros/slug/${slug}`)
                setMacro(macro)
            }
            catch {
                // navigate("/404")
            }
        }
        getBySlug()
    }, [slug])

    useEffect(() => {
        const getInterest = async (macro_id) => {
            try {
                let result = await api.get(`/macros/${macro_id}/interest`)
                setInterest(result.interest)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (context.user && macro) {
            getInterest(macro.id)
        }
    }, [context.user, macro])

    const setMacroInterest = async () => {
        try {
            let result = await api.post(`/macros/${macro.id}/interest`)
            setInterest(result.interest)
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <HeaderFooterLayout>
            <div className={styles.container}>
                {macro &&
                    <>
                        <div className={styles.section} style={{ paddingTop: '1rem', borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                            <div className={[styles.sectionInner, styles.back].join(' ')}>
                                <Button appearance="text" style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}
                                    onClick={() => { navigate(-1) }}>
                                    <ArrowIcon style={{ width: '12px', height: '12px', minWidth: '12px', minHeight: '12px' }} />
                                    Torna indietro
                                </Button>
                            </div>
                            {context.user &&
                                <div className={[styles.sectionInner, styles.interest].join(' ')}>
                                    <Button
                                        inverse
                                        accentColor={interest ? "var(--secondary)" : "var(--tertiary)"}
                                        style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem', alignItems: 'center' }}
                                        onClick={() => {
                                            setInterest(!interest)
                                            setMacroInterest()
                                        }}>
                                        {interest ? "NON MI INTERESSA" : "MI INTERESSA"}
                                        {interest &&
                                            <RemoveFavoriteIcon style={{ width: '16px', height: '16px', minWidth: '16px', minHeight: '16px' }} />
                                        }
                                        {!interest &&
                                            <FavoriteIcon style={{ width: '16px', height: '16px', minWidth: '16px', minHeight: '16px' }} />
                                        }
                                    </Button>
                                </div>
                            }
                            <div className={styles.sectionInner}>
                                <div className={styles.main}>
                                    <div className={styles.macroLeft}>
                                        <div className={typo.subtitle}>Una carriera in {macro.name}</div>
                                        <div className={styles.description}>{macro.description}</div>
                                        <Each of={macro.careers} render={(career) => {
                                            return (
                                                <button className={styles.careerButton} onClick={() => {
                                                    navigate(`/careers/${career.slug}`)
                                                }}>{career.name}</button>
                                            )
                                        }} />
                                    </div>
                                    <div className={styles.macroRight}>
                                        <div className={styles.playerWrapper}>
                                            <ReactPlayer
                                                url={macro.video_url}
                                                controls
                                                width="100%"
                                                height="100%"
                                                className={styles.player}
                                                playIcon={<PlayIcon />}
                                                light={"https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/thumbnails/cover-investment-banking.png"}
                                                config={
                                                    {
                                                        youtube: {
                                                            playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {macro.content[0] &&
                            <div className={styles.section} style={{ borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                                <div className={styles.sectionInner}>
                                    <MacroSection section={macro.content[0]} showPositions={false} />
                                </div>
                                <div className={styles.viewDetail}>
                                    <Button
                                        onClick={() => {
                                            navigate(`/macros/${macro.slug}/detail`)
                                        }}
                                    >
                                        VEDI DETTAGLI
                                        <ArrowIcon style={{ transform: 'rotate(180deg)' }}></ArrowIcon>
                                    </Button>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </HeaderFooterLayout>
    )
}

export default Macro