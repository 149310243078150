import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../api"
import { Each } from "../common/Each"
import CourseCard from "../components/cards/CourseCard"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./ShopCourses.module.css"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Button from "../components/Button"
import AutoplayVerticalCarousel from "../components/AutoplayVerticalCarousel"
import LargeCourseCard from "../components/cards/LargeCourseCard"

const ShopCourses = () => {

    const { t } = useTranslation()
    const [courses, setCourses] = useState(null)
    const [suggestedCourse, setSuggestedCourse] = useState(null)
    const [bestsellers, setBestsellers] = useState(null)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        const getBestsellersCourses = async () => {
            try {
                let bestsellers = await api.get("/courses/0/bestsellers")
                setBestsellers(bestsellers)
            }
            catch (e) {
                console.error(e)
            }
        }


        const getSuggestedCourse = async () => {
            try {
                let suggestedCourse = await api.get("/courses/0/suggested")
                setSuggestedCourse(suggestedCourse)
            }
            catch (e) {
                console.error(e)
            }
        }

        async function getCourses() {
            try {
                let courses = await api.get("/courses")
                setCourses(courses)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!courses) {
            getCourses()
        }
        if (!suggestedCourse) {
            getSuggestedCourse()
        }
        if (!bestsellers) {
            getBestsellersCourses()
        }
    })

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Corsi</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                {courses && courses.length > 0 &&
                    <>
                        <div className={styles.section} style={{ backgroundColor: 'var(--background-color)', paddingTop: 0, paddingBottom: 0 }}>
                            <div className={styles.sectionInner}>
                                <div className={styles.banner}>
                                    <div className={styles.bannerLeft}>
                                        <div className={typo.title}>
                                            Scopri i nostri corsi
                                        </div>
                                        <div className={typo.body} style={{ paddingBottom: '2rem' }}>
                                            Stiamo creando insieme alle migliori aziende di ogni settore in Italia percorsi di formazione studiati appositamente per lavorare nel loro ambito e aiutarti ad entrare o crescere nel mondo del lavoro. Scopri quali percorsi sono già disponibili
                                        </div>
                                        <Button
                                            onClick={() => {
                                                let elem = document.querySelector('#suggested')
                                                if (elem) {
                                                    elem.scrollIntoView({ behavior: 'smooth' })
                                                }
                                            }}
                                            accentColor={'var(--tertiary)'}
                                            style={{ padding: '.7rem 1rem', fontSize: '1.125rem' }}>
                                            SCOPRI I CORSI
                                        </Button>
                                    </div>
                                    <div className={styles.bannerRight}>
                                        <AutoplayVerticalCarousel
                                            elements={courses.map(c => c.thumbnail).slice(0, 4)}
                                            cardStyle={{ padding: 0, borderRadius: '1rem', overflow: 'hidden' }}
                                            imgStyle={{ objectFit: 'cover' }}
                                        />
                                        <AutoplayVerticalCarousel
                                            reverse
                                            elements={courses.map(c => c.thumbnail).slice(0, 4)}
                                            cardStyle={{ padding: 0, borderRadius: '1rem', overflow: 'hidden' }}
                                            imgStyle={{ objectFit: 'cover' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {suggestedCourse &&
                            <div id="suggested" className={styles.section} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(21, 224, 255, 0.2) 20%, rgba(255,255,255,0) 100%)' }}>
                                <div className={styles.sectionInner}>
                                    <div className={styles.sectionTitle}>
                                        <div style={{ width: '100%', flexDirection: 'column' }}>
                                            <div className={typo.subtitle}>Perfetto per te 🏅</div>
                                            <div className={typo.caption} style={{ fontSize: '0.75rem', opacity: 0.75 }}>Un corso scelto sulla base delle tue caratteristiche e del tuo percorso di carriera.</div>
                                        </div>
                                    </div>
                                    <div className={styles.courses}>
                                        <LargeCourseCard course={suggestedCourse} />
                                    </div>
                                </div>
                            </div>
                        }

                        {bestsellers && bestsellers.length > 0 &&
                            <div className={styles.section} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(207, 85, 255, 0.12) 20%, rgba(255,255,255,0) 100%)' }}>
                                <div className={styles.sectionInner}>
                                    <div className={styles.sectionTitle}>
                                        <div style={{ width: '100%', flexDirection: 'column' }}>
                                            <div className={typo.subtitle}>Bestsellers ✨</div>
                                            <div className={typo.caption} style={{ fontSize: '0.75rem', opacity: 0.75 }}>I corsi di maggior successo, scelti e recensiti dalla community.</div>
                                        </div>
                                    </div>
                                    <div className={styles.courses}>
                                        {bestsellers &&
                                            <Each of={bestsellers} render={(c, index) => {
                                                return (
                                                    <div className={styles.courseCardWrapper}>
                                                        <CourseCard course={c}></CourseCard>
                                                    </div>
                                                )
                                            }} />
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={styles.section} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,178,255,0.12) 20%, rgba(255,255,255,0) 100%)' }}>
                            <div className={styles.sectionInner}>
                                <div className={styles.sectionTitle}>
                                    <div className={typo.subtitle}>Tutti i corsi</div>
                                </div>
                                <div className={styles.courses}>
                                    {courses &&
                                        <Each of={courses} render={(c, index) => {
                                            return (
                                                <div className={styles.courseCardWrapper}>
                                                    <CourseCard course={c}></CourseCard>
                                                </div>
                                            )
                                        }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        </HeaderFooterLayout>
    )
}

export default ShopCourses