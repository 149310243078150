import { Each } from "../common/Each"
import styles from "./Stepper.module.css"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"
import { useEffect, useState } from "react"

const Stepper = ({nSteps, nCompleted}) => {

    const [perc, setPerc] = useState(0)

    useEffect(() => {
        if(nSteps && nCompleted){
            const p = (nCompleted/nSteps)*100
            setPerc(p)
        }
    }, [nSteps, nCompleted])

    return (
        <div className={styles.container}>
            <div className={styles.background}>
                <div className={styles.progress} style={{width: `${perc}%`}}></div>
                <Each of={Array(nSteps).fill(null)} render={(s, i) => {
                    return (
                        <div className={`${styles.step} ${nCompleted >= i + 1 ? styles.completed : ''}`}
                        style={{borderRadius: i === 0 ? '20px 0 0 20px' : 0}}
                        >
                            <div className={`${styles.check} ${nCompleted >= i + 1 ? styles.completed : ''}`}>
                                <CheckIcon />    
                            </div> 
                        </div>
                    )
                }}/>
            </div>
        </div>
    )

}

export default Stepper