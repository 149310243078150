import Daily from "@daily-co/daily-js";
import { DailyProvider } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import remarkGfm from "remark-gfm";
import api from "../api";
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg";
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg";
import { Each } from "../common/Each";
import Button from "../components/Button";
import CourseBadge from "../components/CourseBadge";
import DailyRoom from "../components/daily/DailyRoom";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import MaterialButton from "../components/MaterialButton";
import Spinner from "../components/Spinner";
import TeachersBadge from "../components/TeachersBadge";
import typo from "../typography.module.css";
import { formatDateV2, formatTimeV2 } from "../utils";
import styles from "./Lesson.module.css";

var interval = null

const Lesson = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roomName } = useParams();
  const [lesson, setLesson] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [call, setCall] = useState(null)
  const [minutesDiff, setMinutesDiff] = useState(null)
  const [roomError, setRoomError] = useState(null);

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval)
        interval = null
      }

      return () => {
        call.leave()
        call.destroy()
      }
    }
  }, [])


  useEffect(() => {

    const getLessonTeachers = async (lessonId) => {
      try {
        const teachers = await api.get(`/lessons/${lessonId}/teachers`);

        if (teachers.length === 0) {
          throw new Error("No teachers found");
        }

        setTeachers(teachers);
      } catch (e) {
        console.error(e);
      }
    };

    const getLessonMaterial = async (lessonId) => {
      try {
        const materials = await api.get(`/lessons/${lessonId}/materials`);

        setMaterials(materials);
      } catch (e) {
        console.error(e);
      }
    };

    if (lesson) {
      getLessonTeachers(lesson.id)
      getLessonMaterial(lesson.id)

      interval = setInterval(() => {
        calculateMinutesDiff(lesson.starts_at)
      }, 1000)

      if (!lesson.ended_at && minutesDiff && minutesDiff > -30) {
        try {
          const call = Daily.createCallObject({ url: `https://startingfinance.daily.co/${lesson.room_name}` })
          call.join()
          setCall(call)
        }
        catch (e) {
          console.error(e)
        }
      }
    }
  }, [lesson]);

  useEffect(() => {

    const joinCall = async (call) => {
      try {
        await call.join()
        setCall(call)
      }
      catch (e) {
        console.error(e)
        setRoomError(e.errorMsg)
      }
    }

    if (lesson && minutesDiff) {
      if (!lesson.ended_at && minutesDiff > -30) {
        try {
          const call = Daily.createCallObject({ url: `https://startingfinance.daily.co/${lesson.room_name}` })
          joinCall(call);
        }
        catch (e) {
          console.error(e)
        }
      }
    }
  }, [minutesDiff, lesson])

  useEffect(() => {
    const getLesson = async (roomName) => {
      try {
        const lesson = await api.get(`/lessons/${roomName}`);

        setLesson(lesson);
      } catch (e) {
        if (e.code === 6) {
          navigate("/404");
        }
        console.error(e);
      }
    };

    if (roomName) {
      getLesson(roomName);
    }

  }, [roomName])

  const calculateMinutesDiff = (dateString) => {
    var now = new Date();
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))

    const diff = now - date
    const mDiff = parseInt(diff / (1000 * 60))
    setMinutesDiff(mDiff)
  }

  return (
    <HeaderFooterLayout>
      <div className={styles.container}>
        {lesson && (
          <>
            <div
              className={styles.section}
              style={{ backgroundColor: "var(--background-color)", paddingBottom: 0 }}
            >
              <div className={styles.sectionInner}>
                {/* <Button appearance="text" style={{ display: 'flex', flexDirectio: 'row', alignItems: 'center', gap: '.2rem', marginBottom: '2rem' }}
                  onClick={() => { navigate(-1) }}>
                  <ArrowIcon className={styles.arrowIcon}></ArrowIcon>Torna indietro
                </Button> */}
                <div className={styles.videoPlaceholder}>
                  {!lesson.ended_at &&
                    <>
                      {!minutesDiff &&
                        <div className={styles.noLesson}>
                          <div className={typo.headline}
                            style={{
                              color: 'var(--background-color)',
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '0.5rem',
                              alignItems: 'center'
                            }}>
                            <Spinner />
                            Caricando...
                          </div>
                        </div>
                      }
                      {minutesDiff && minutesDiff >= -30 && call &&
                        <DailyProvider callObject={call}>
                          <DailyRoom lesson_id={lesson.id} />
                        </DailyProvider>
                      }
                      {minutesDiff && minutesDiff < -30 &&
                        <div className={styles.noLesson}>
                          <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                            La lezione è programmata per le ore {formatTimeV2(lesson.starts_at)} di {formatDateV2(lesson.starts_at)} e sarà accessibile mezz'ora prima dell'inizio.
                          </div>
                        </div>
                      }
                    </>
                  }
                  {lesson.ended_at &&
                    <div className={styles.noLesson}>
                      <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                        La lezione è terminata {formatDateV2(lesson.ended_at)} alle {formatTimeV2(lesson.ended_at)}. La registrazione sarà disponibile a breve.
                      </div>
                    </div>
                  }
                  {roomError &&
                    <div className={styles.noLesson}>
                      <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                        {roomError}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div
              className={styles.section}
              style={{ backgroundColor: "var(--background-color)", paddingTop: 0 }}
            >
              <div className={styles.sectionInner}>
                <div className={styles.lessonDetails}>
                  <div>
                    <TeachersBadge teachers={teachers} />
                  </div>
                  <div className={styles.lessonTime}>
                    {formatTimeV2(lesson.starts_at)} -{" "}
                    {formatTimeV2(lesson.ends_at)} <ClockIcon />
                  </div>
                </div>
              </div>

              <div className={styles.sectionInner}>
                <div className={styles.lessonHeader}>
                  <div className={styles.lessonTitle}>
                    <div className={typo.title}>
                      {lesson?.module.name} - {lesson?.name}
                    </div>
                    <CourseBadge type={lesson?.module.edition.course.type} />
                  </div>
                  {lesson?.description && (
                    <div className={styles.lessonDescription}>
                      <ReactMarkdown children={lesson.description} remarkPlugins={[remarkGfm]} />
                    </div>
                  )}
                </div>
              </div>

              {materials.length > 0 &&
                <div className={styles.sectionInner} style={{ flexDirection: "column", paddingTop: "1rem" }}>
                  <div className={typo.subheadline}>
                    Materiali didattici
                  </div>
                  <div className={styles.materials}>
                    <Each of={materials} render={(item) =>
                      <div className={styles.material}>
                        <MaterialButton material={item} />
                      </div>
                    } />
                  </div>
                </div>
              }
            </div>
          </>
        )}
      </div>
    </HeaderFooterLayout >
  );
};

export default Lesson;
