import { signInAnonymously } from 'firebase/auth';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { useEffect, useRef, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import api from './api';
import MainContext from "./common/MainContext";
import { FirebaseKeys } from './common/constants';
import useWindowDimensions from './common/hooks/useWindowDimensions';
import "./common/i18n";
import Loader from './components/Loader';
import NotificationBanner from './components/NotificationBanner';
import ProtectedRoute from './components/ProtectedRoute';
import LoginDialog from './components/dialogs/LoginDialog';
import NotificationPermissionDialog from './components/dialogs/NotificationPermissionDialog';
import PaymentDialog from './components/dialogs/PaymentDialog';
import SideNav from './components/layouts/SideNav';
import ThemeProvider from "./components/layouts/ThemeProvider";
import app, { auth } from "./firebase";
import ActionHandler from './pages/ActionHandler';
import Career from './pages/Career';
import Careers from './pages/Careers';
import Cart from './pages/Cart';
import Course from './pages/Course';
import Dashboard from "./pages/Dashboard";
import Lesson from './pages/Lesson';
import Login from './pages/Login';
import Macro from './pages/Macro';
import Messages from './pages/Messages';
import MyPath from './pages/MyPath';
import Notifications from "./pages/Notifications";
import Order from './pages/Order';
import PageNotFound from './pages/PageNotFound';
import Profile from './pages/Profile';
import Releases from './pages/Releases';
import Search from './pages/Search';
import ShopCourse from './pages/ShopCourse';
import Courses from './pages/ShopCourses';
import Signup from './pages/Signup';
import Test from './pages/Test';
import Video from './pages/Video';
import "./themes.css";
import typo from "./typography.module.css";
import { closeWebSocket, initWebSocket } from './websocket';
import Survey from './pages/Survey';
import Home from './pages/Home';
import MacroDetail from './pages/MacroDetail';

function App() {

  const { width } = useWindowDimensions()
  const [user, setUser] = useState(null)
  const [dropdown, setDropdown] = useState(null)
  const [checkout, setCheckout] = useState({ open: false, order: null })
  const [cart, setCart] = useState(null)
  const [loginDialog, setLoginDialog] = useState(null)
  const [sidenav, setSideNav] = useState(true)
  const [surveyCompleted, setSurveyCompleted] = useState(true)
  const [anonymousId, setAnonymousId] = useState(null)
  const [notificationPermissions, setNotificationPermissions] = useState(false)
  const [notificationBanner, setNotificationBanner] = useState(null)
  const [notifications, setNotifications] = useState(null)
  const [unreadMessages, _setUnreadMessages] = useState(0)
  const [onboarding, setOnboarding] = useState(null)

  const setUnreadMessages = data => {
    unreadMessageRef.current = data
    _setUnreadMessages(data)
  }

  const context = {
    user: user,
    setUser: setUser,
    dropdown: dropdown,
    setDropdown: setDropdown,
    cart: cart,
    setCart: setCart,
    checkout: checkout,
    setCheckout: setCheckout,
    loginDialog: loginDialog,
    setLoginDialog: setLoginDialog,
    sidenav: sidenav,
    setSideNav: setSideNav,
    notificationPermissions: notificationPermissions,
    setNotificationPermissions: setNotificationPermissions,
    notificationBanner: notificationBanner,
    setNotificationBanner: setNotificationBanner,
    notifications: notifications,
    setNotifications: setNotifications,
    unreadMessages: unreadMessages,
    setUnreadMessages: setUnreadMessages,
    onboarding: onboarding,
    setOnboarding: setOnboarding
  }

  const unreadMessageRef = useRef(context.unreadMessages)

  useEffect(() => {

    //Gestisce le notifiche push ricevute con app aperta.
    isSupported().then((supported) => {
      if (supported) {
        let messaging = getMessaging(app)
        if (messaging) {
          onMessage(messaging, (payload) => {
            setNotificationBanner({
              title: payload.notification.title,
              body: payload.notification.body,
              image: payload.notification.image,
              url: payload.data.url
            })
          })
        }
      }
    })

    auth.authStateReady().then(() => {
      auth.onAuthStateChanged((user) => {
        //Se l'utente è loggato
        if (user) {
          //Se l'utente è anonimo, salvo il suo id nello stato (per poterlo eliminare quando fa la login, vedi funzione deleteAnonymous)
          if (user.isAnonymous) {
            setAnonymousId(user.uid)
            context.setUser({ id: 0, isAnonymous: user.isAnonymous })
          }
          else {
            //Se il browser lo supporta chiede permesso per le notifiche push.
            isSupported().then((supported) => {
              if (supported) {
                if (Notification.permission === 'default') {
                  context.setNotificationPermissions(true)
                }
                if (Notification.permission === 'granted') {
                  getNotificationsToken()
                }
              }
            })

            //Ottiene le info dell'utente dal backend.
            const getUser = async () => {
              try {
                let u = await api.get("/user")
                if (u) {
                  context.setUser(u)
                  context.setLoginDialog(false)
                }
              }
              catch (e) {
                console.error(e)
              }
            }

            getUser()
          }
        }
        //Se l'utente non è loggato
        else {
          //Esegui la login anonima con firebase
          const anonymousSignIn = async () => {
            let credentials = await signInAnonymously(auth)
            context.setUser({ id: 0, isAnonymous: credentials.user.isAnonymous })
          }

          if (!context.user) {
            anonymousSignIn()
          }
        }
      });
    })
  }, []);

  /**
   * Quando cambia l'utente nel context
   */
  useEffect(() => {
    if (context.user && !context.user.isAnonymous) {

      const getSurvey = async () => {
        try {
          await api.get("/user/survey")
          setSurveyCompleted(true)
        }
        catch (e) {
          setSurveyCompleted(false)
          console.error(e)
        }
      }

      const deleteAnonymous = async () => {
        if (anonymousId) {
          try {
            await api.post(`/anonymous`, { uid: anonymousId })
          }
          catch (e) {
            console.error(e)
          }
        }
      }

      const getCart = async () => {
        try {
          let cart = await api.get("/cart")
          setCart(cart)
          context.setCart(cart)
        }
        catch (e) {
          console.error(e)
        }
      }

      const getNotifications = async () => {
        try {
          let notifications = await api.get("/notifications?page=1&per=4")
          context.setNotifications(notifications)
        }
        catch (e) {
          console.error(e)
        }
      }

      //Elimina l'utente anonimo, ottieni il carrello, le notifiche e inizializza la connessione websocket per i messaggi
      deleteAnonymous()
      getCart()
      getNotifications()
      getSurvey()
      initWebSocket(auth.currentUser.uid, handleReceivedMessage)
    }

    return () => {
      // Chiudi la connessione WebSocket quando il componente viene smontato
      closeWebSocket();
    };
  }, [context.user])


  //Handler per i messaggi del websocket.
  const handleReceivedMessage = (data) => {
    if (data.type === "unread") {
      setUnreadMessages(data.value)
    }
    if (data.type === "received") {
      setUnreadMessages(unreadMessageRef.current + 1)
      if (!window.location.pathname.includes("messages")) {
        context.setNotificationBanner({ title: data.sender_name, body: data.body, url: "/messages", image: data.sender_picture })
      }
    }
    document.dispatchEvent(new CustomEvent('message-received', { detail: data }));
  };

  //Ottiene il token FCM e lo invia al backend.
  const getNotificationsToken = async () => {
    try {
      let supported = await isSupported()
      if (supported) {
        await navigator.serviceWorker.register('/firebase-messaging-sw.js')
        let messaging = getMessaging(app)
        if (messaging) {
          if (Notification.permission === "granted") {
            console.debug("Getting Token from firebase")
            try {
              let token = await getToken(messaging, { vapidKey: FirebaseKeys.Vapid })

              console.debug("Sending token to backend")
              await api.post("/tokens", { token: token })
            }
            catch (e) {
              console.error(e)
            }
          } else {
            console.log("Notifications Permission Denied")
          }
        }
      }
    } catch (e) {
      console.error(e)

    }
  }


  useEffect(() => {

    const updateOnboarding = async (onboarding) => {
      let user_onboarding = {
        id: onboarding.id,
        steps: onboarding.steps.map(step => {
          let actions = step.actions.map(action => {
            if (step.type === 'page') {
              return { id: action.id, page: action.page, actual: action.actual, completed: action.completed }
            }
            else {
              return { id: action.id, completed: action.completed }
            }
          })
          return { id: step.id, actions: actions }
        })
      }

      try {
        await api.put("/user/onboarding", user_onboarding)
      }
      catch (e) {
        console.error(e)
      }
    }

    if (context.onboarding) {
      updateOnboarding(context.onboarding)
    }
  }, [context.onboarding])


  return (
    <Router>
      <MainContext.Provider value={context}>
        <ThemeProvider theme={'starting-finance'}>
          {context.user &&
            <div className="container">

              {!context.user?.isAnonymous && surveyCompleted &&
                <SideNav open={width >= 768}></SideNav>
              }

              <PaymentDialog order={context.checkout.order} open={context.checkout.open} onClose={() => { context.setCheckout({ open: false, order: null }) }} />
              <LoginDialog open={context.loginDialog} onClose={() => { context.setLoginDialog(false) }} />
              <NotificationPermissionDialog open={context.notificationPermissions} onClose={() => {
                context.setNotificationPermissions(false)
                getNotificationsToken()
              }} />
              <NotificationBanner notification={context.notificationBanner} />

              <div className='content'>

                {surveyCompleted &&
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/actions" element={<ActionHandler />} />

                    <Route path="/" element={<Home />} />
                    <Route path="/courses" element={<Courses />} />
                    <Route path="/courses/:slug" element={<ShopCourse />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/careers/:slug" element={<Career />} />
                    <Route path="/macros/:slug" element={<Macro />} />
                    <Route path="/macros/:slug/detail" element={<MacroDetail />} />

                    <Route path="/my-path" element={<ProtectedRoute component={<><MyPath /></>} />} />
                    <Route path="/profile" element={<ProtectedRoute component={<><Profile /></>} />} />
                    <Route path="/my-path/courses/:slug" element={<ProtectedRoute component={<><Course /></>} />} />
                    <Route path="/cart" element={<ProtectedRoute component={<><Cart /></>} />} />
                    <Route path="/notifications" element={<ProtectedRoute component={<><Notifications /></>} />} />
                    <Route path="/messages" element={<ProtectedRoute component={<><Messages /></>} />} />
                    <Route path="/orders/:orderNo" element={<ProtectedRoute component={<><Order /></>} />} />
                    <Route path='/lessons/:roomName' element={<ProtectedRoute component={<><Lesson /></>} />} />
                    <Route path='/search' element={<ProtectedRoute component={<><Search /></>} />} />
                    <Route path='/tests/:testId' element={<ProtectedRoute component={<><Test /></>} />} />
                    <Route path='/releases' element={<ProtectedRoute component={<><Releases /></>} />} />
                    <Route path='/videos/:slug' element={<ProtectedRoute component={<><Video /></>} />} />
                    <Route path='/survey' element={<ProtectedRoute component={<><Survey /></>} />} />
                  </Routes>
                }

                {!surveyCompleted && <Survey />}
              </div>

            </div>
          }
          {!context.user &&
            <div className="loader-container">
              <Loader />
              <div className={typo.headline}>
                Caricamento...
              </div>
            </div>
          }
        </ThemeProvider>
      </MainContext.Provider>
    </Router>
  );
}

export default App;
