import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import { ReactComponent as SubscribeIcon } from "../assets/images/icons/ic-subscribe.svg"
import { Each } from "../common/Each"
import { CourseType, TagType } from "../common/constants"
import typo from "../typography.module.css"
import Button from "./Button"
import CourseBadge from "./CourseBadge"
import styles from "./CourseSubscribe.module.css"
import ProgramTimeline from "./ProgramTimeline"
import EditionLessonsCard from "./cards/EditionLessonsCard"
import TeachersBadge from "./TeachersBadge"
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg"

const CourseSubscribe = ({ slug }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const [course, setCourse] = useState(null)
    const [teachers, setTeachers] = useState([])
    const [selectedEdition, setSelectedEdition] = useState(null)

    useEffect(() => {
        const getCourse = async (slug) => {
            try {
                let course = await api.get(`/user/courses/${slug}`)
                setCourse(course)
                setTeachers(course.teachers ?? [])
            }
            catch (error) {
                navigate("/404")
            }
        }

        if (!course) {
            getCourse(slug)
        }
    }, [slug, course, navigate])

    const subscribe = async () => {
        try {
            await api.get(`/editions/${selectedEdition}/subscribe`)
            window.location.reload(false)
        }
        catch (error) {
            console.error(error)
        }
    }


    return (

        <div className={styles.container}>

            {course &&
                <>
                    <div className={styles.section}>
                        <div className={styles.sectionInner}>
                            <div className={styles.overviewLeft}>
                                <CourseBadge type={course.type}></CourseBadge>
                                <div className={typo.subtitle}>{course.name}</div>
                                <div className={styles.mobilePlayerWrapper}>
                                    <ReactPlayer
                                        url={course.video_url}
                                        controls
                                        width="100%"
                                        height="100%"
                                        className={styles.player}
                                        playIcon={<PlayIcon />}
                                        light={true}
                                        config={
                                            {
                                                youtube: {
                                                    playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className={typo.body}>{course.description}</div>
                                <div className={styles.featureRow}>
                                    <div className={styles.feature}>
                                        <ClockIcon></ClockIcon> {course.duration} ore
                                    </div>
                                    <Each of={course.tags} render={(tag) => {
                                        if (tag.type === TagType.Feature) {
                                            return (
                                                <div className={styles.feature}>
                                                    <img src={tag.icon} alt={tag.name} /> {t(`tags.${tag.name}`)}
                                                </div>
                                            )
                                        }
                                    }} />
                                </div>
                                <div className={styles.classesRow}>
                                    <div className={typo.headline} style={{ fontSize: '1rem', textTransform: 'uppercase', paddingTop: '.5rem' }}>scegli una classe</div>
                                    <div className={styles.groups}>
                                        <Each of={course.editions.reverse()} render={(edition) => {
                                            return (
                                                <>
                                                    {edition.modules.flatMap(module => module.lessons).length > 0 &&
                                                        <div className={styles.groupCard} onClick={() => { setSelectedEdition(edition.id) }}>
                                                            <EditionLessonsCard edition={edition} active={selectedEdition === edition.id} />
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }} />
                                        {course.editions.length === 0 &&
                                            <div className={typo.headline} style={{ whiteSpace: 'wrap' }}>
                                                Al momento non ci sono edizioni a cui puoi iscriverti. Presto ne arriveranno altre!
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className={styles.actionRow}>
                                    <Button style={{ fontSize: '1rem', width: '100%', padding: '.5rem' }} disabled={!selectedEdition}
                                        onClick={subscribe}
                                    >
                                        <SubscribeIcon style={{ width: '24px', height: '24px', minWidth: '24px', minHeight: '24px' }} />
                                        {t('courses.subscribeClass').toUpperCase()}
                                    </Button>
                                </div>
                            </div>
                            <div className={styles.overviewRight}>
                                <div className={styles.playerWrapper}>
                                    <ReactPlayer
                                        url={course.video_url}
                                        controls
                                        width="100%"
                                        height="100%"
                                        className={styles.player}
                                        playIcon={<PlayIcon />}
                                        light={true}
                                        config={
                                            {
                                                youtube: {
                                                    playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <TeachersBadge teachers={teachers} showQualification pictureStyle={{
                                    width: "56px",
                                    height: "56px"
                                }} nameStyle={{ fontSize: "1.15rem" }} />
                            </div>
                        </div>
                    </div>

                    {course.program &&
                        <div className={styles.section} style={{ alignItems: 'center', background: 'var(--background-secondary-color)' }}>
                            <div className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                                <div className={typo.title} style={{ fontSize: '1.15rem', width: '100%', textAlign: 'center' }}>
                                    {t('masterclass.program')}
                                </div>
                                <ProgramTimeline hideTimeline={course.type === CourseType.Masterclass} program={course.program} />
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default CourseSubscribe
