import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth"
import { useContext, useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../api"
import comuni from "../assets/comuni.json"
import { ReactComponent as AgeIcon } from "../assets/images/icons/ic-age.svg"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { ReactComponent as BackpackIcon } from "../assets/images/icons/ic-backpack.svg"
import { ReactComponent as CityIcon } from "../assets/images/icons/ic-city.svg"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-edit.svg"
import { ReactComponent as EmailIcon } from "../assets/images/icons/ic-email.svg"
import { ReactComponent as FavoriteRemoveIcon } from "../assets/images/icons/ic-favorite-remove.svg"
import { ReactComponent as ImageEditIcon } from "../assets/images/icons/ic-image-edit.svg"
import { ReactComponent as LogoutIcon } from "../assets/images/icons/ic-logout.svg"
import { ReactComponent as MoneybagIcon } from "../assets/images/icons/ic-moneybag.svg"
import { ReactComponent as UniversityIcon } from "../assets/images/icons/ic-university.svg"
import { ReactComponent as InsertImageIllustration } from "../assets/images/illustrations/il-insert-image.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import universities from "../assets/universities.json"
import { Each } from "../common/Each"
import MainContext from "../common/MainContext"
import { CourseStatus } from "../common/constants"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Button from "../components/Button"
import ImageCropper from "../components/ImageCropper"
import RadarChart from "../components/RadarChart"
import Select from "../components/Select"
import TextInput from "../components/TextInput"
import Card from "../components/cards/Card"
import PathCourseCard from "../components/cards/PathCourseCard"
import Dialog from "../components/dialogs/Dialog"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import { auth } from "../firebase"
import typo from "../typography.module.css"
import { capitalize } from "../utils"
import styles from "./Profile.module.css"

const Profile = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const context = useContext(MainContext)
    const { width } = useWindowDimensions()
    const [editMode, setEditMode] = useState(false)
    const [error, setError] = useState(null)

    const [profilePic, setProfilePic] = useState(null)
    const [name, setName] = useState(context.user?.name ?? '')
    const [surname, setSurname] = useState(context.user?.surname ?? '')
    const [age, setAge] = useState(context.user?.age ?? '')
    const [ageNumeric, setAgeNumeric] = useState(parseInt(context.user?.age))
    const [city, setCity] = useState(context.user?.city ?? '')
    const [employment, setEmployment] = useState(context.user?.employment ?? '')
    const [gender, setGender] = useState(context.user?.gender ?? '')
    const [university, setUniversity] = useState('')
    const [loading, setLoading] = useState(false)
    const [userChanged, setUserChanged] = useState(false)

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatNewPassword, setRepeatNewPassword] = useState('')

    const [dialogOpen, setDialogOpen] = useState(false)

    const [imgFile, setImgFile] = useState(null)
    const [cropMode, setCropMode] = useState(false)

    const [alert, setAlert] = useState(null)
    const [courses, setCourses] = useState(null)
    const [macros, setMacros] = useState(null)

    const [salary, setSalary] = useState(null)
    const [workHour, setWorkHour] = useState(null)
    const [career, setCareer] = useState(null)

    const radarDatasets = [
        {
            values: [34, 57, 81, 45, 90],
            labels: ['Estroversione', 'Apertura', 'Gradevolezza', 'Coscienziosità', 'Stabilità'],
            colors: ['#FAB666', '#60D265', '#4CB4FF', '#FA6676', '#B966FA'],
            tension: 1,
            stroke: 'rgb(47, 198, 160)',
            strokeWidth: 2,
            fill: 'rgba(47, 198, 160, 10%)'
        }
    ]

    const radarOptions = {
        grid: {
            paddingVertical: 40,
            paddingHorizontal: 100,
            levels: 4,
            sideLength: 100,
            borderRadius: 10,
            fill: '#2A2A2A04',
            stroke: '#2A2A2A10',
            strokeWidth: 2
        },
        labels: {
            dots: true,
            dotColors: ['#FAB666', '#60D265', '#4CB4FF', '#FA6676', '#B966FA'],
            dotsRadius: 5,
            dotsDelta: 1.25,
            font: {
                family: 'Manrope',
                size: '14px',
                weight: '800'
            },
            padding: 12
        }
    }

    const circularData = [
        {
            value: 40,
            color: '#4CB4FF',
            label: 'Hard Skills'
        },
        {
            value: 12,
            color: '#FFC24C',
            label: 'Soft Skills'
        },
        {
            value: 23,
            color: '#FA6676',
            label: 'Leadership'
        },
        {
            value: 9,
            color: '#B966FA',
            label: 'General Skills'
        }
    ]

    const otherCarrers = [
        {
            name: 'Real Estate Analyst',
        },
        {
            name: 'Tax Manager',
        },
        {
            name: 'Business Analyst',
        }
    ]

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })

        const getCareer = async (careerId) => {
            try {
                const career = await api.get(`/careers/${careerId}`)
                console.debug(career)
                setCareer(career)

            }
            catch (e) {
                console.error(e)
            }
        }

        const getSurvey = async () => {
            try {
                const survey = await api.get("/user/survey")
                setSalary(survey.content.find(q => q.id === 3).answer.value)
                setWorkHour(survey.content.find(q => q.id === 6).answer.value)

                const careerId = survey.content.find(q => q.id === 10).answer.careerId
                if (careerId) {
                    getCareer(careerId)
                }
            }
            catch (e) {
                console.error(e)
            }
        }

        getSurvey()

    }, [])

    useEffect(() => {

        const getCourses = async () => {
            try {
                let courses = await api.get("/user/courses")
                setCourses(courses)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getMacros = async () => {
            try {
                let macros = await api.get("/macros/interest")
                setMacros(macros)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (context.user) {
            setName(context.user.name)
            setSurname(context.user.surname)
            setAge(context.user.age)
            setCity(context.user.city)
            setEmployment(context.user.employment)
            setGender(context.user.gender)
            getCourses()
            getMacros()
        }
    }, [context])

    useEffect(() => {
        const checkChanges = () => {
            if (context.user) {
                let changed = (name !== context.user.name) ||
                    (surname !== context.user.surname) ||
                    (ageNumeric !== context.user.age) ||
                    (city !== context.user.city) ||
                    (gender !== context.user.gender) ||
                    (employment !== context.user.employment) ||
                    (university !== context.user.university) ||
                    (newPassword !== '' && repeatNewPassword !== '' && newPassword === repeatNewPassword) ||
                    profilePic
                setUserChanged(changed)
            }
        }

        checkChanges()
    }, [context.user, name, surname, age, city, university, newPassword, repeatNewPassword, profilePic, ageNumeric, employment, gender])

    const setMacroInterest = async (macro_id) => {
        try {
            await api.post(`/macros/${macro_id}/interest`)
        }
        catch (e) {
            console.error(e)
        }
    }

    const onNameChange = (value) => {
        setName(value)
    }

    const onSurnameChange = (value) => {
        setSurname(value)
    }

    const onCityChange = (value) => {
        setCity(value)
    }

    const onUniversityChange = (value) => {
        setUniversity(value)
    }

    const onAgeChange = (value) => {
        setAge(value)
        const numericAge = parseInt(value)
        if (numericAge < 13) {
            setError({ code: -1, message: t('errors.invalidAge') })
        }
        else {
            setError(null)
        }
        if (!isNaN(numericAge)) {
            setAgeNumeric(numericAge)
        }
    }

    const onOldPasswordChange = (value) => {
        setOldPassword(value)
    }

    const onNewPasswordChange = (value) => {
        setNewPassword(value)
        setError(checkPasswords() ? null : { code: 0, message: t('errors.passwordNotEquals') })
    }

    const onRepeatNewPasswordChange = (value) => {
        setRepeatNewPassword(value)
        setError(checkPasswords() ? null : { code: 0, message: t('errors.passwordNotEquals') })
    }

    const updateUserPassword = async () => {
        try {
            await updatePassword(auth.currentUser, newPassword)
        }
        catch (error) {
            setError({ code: 0, message: error.message })
        }
    }

    const onSaveChanges = async () => {
        try {
            setLoading(true)
            if (!loading) {

                const formData = new FormData();
                formData.append("name", name);
                formData.append("surname", surname);
                formData.append("city", city);
                formData.append("age", ageNumeric);
                formData.append("gender", gender);
                formData.append("university", university); // TODO verificare con il Team SF se tenerla
                formData.append("employment", employment)
                if (profilePic) {
                    let blob = await fetch(profilePic).then(r => r.blob());
                    formData.append("picture", blob, `${context.user.id}-picture.jpg`);
                }

                const user = await api.put("/user", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                if (user) {
                    context.setUser(user)
                    setProfilePic(null)
                }

                if (newPassword !== '' && repeatNewPassword !== '' && (newPassword === repeatNewPassword)) {
                    const credential = EmailAuthProvider.credential(
                        auth.currentUser.email,
                        oldPassword
                    )
                    try {
                        await reauthenticateWithCredential(auth.currentUser, credential)
                    }
                    catch (error) {
                        setError({ code: 0, message: 'Autenticazione fallita.' })
                    }
                    await updateUserPassword()
                }
                setOldPassword('')
                setNewPassword('')
                setRepeatNewPassword('')
                setDialogOpen(true)
                setAlert({ title: t('profile.saved'), message: t('profile.changesSaved') })
            }
            setLoading(false)
        }
        catch (e) {
            console.error(e)
        }
    }

    const checkPasswords = () => {
        return (newPassword.length > 0 && repeatNewPassword.length > 0) ? newPassword === repeatNewPassword : true
    }

    async function handleLogout() {
        localStorage.removeItem("userTests")
        localStorage.removeItem("userTestIndexes")
        await auth.signOut()
        context.setUser(null)
        context.setCart(null)
        context.setOnboarding(null)
        navigate("/")
    }

    const employmentOptions = [
        {
            id: 0,
            name: t(`profile.employments.student`),
            value: 'student'
        },
        {
            id: 1,
            name: t(`profile.employments.worker`),
            value: 'worker'
        },
        {
            id: 2,
            name: t(`profile.employments.studentWorker`),
            value: 'studentWorker'
        },
        {
            id: 3,
            name: t(`profile.employments.neither`),
            value: 'neither'
        }
    ]

    const genders = [
        {
            id: 0,
            name: "Maschio",
            value: "male"
        },
        {
            id: 1,
            name: "Femmina",
            value: "female"
        },
        {
            id: 2,
            name: "Altro",
            value: "other"
        }
    ]

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Profilo</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.containerRow} style={{ alignItems: 'flex-start' }}>
                    {editMode &&
                        <button className={styles.goBackButton}
                            onClick={() => {
                                setEditMode(false)
                                setProfilePic(null)
                            }}>
                            <ArrowIcon></ArrowIcon>
                            {t('profile.goBack')}
                        </button>
                    }
                </div>
                <div className={styles.containerRow}>

                    <div className={styles.columnLeft}>
                        <div className={styles.profileCard}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img className={styles.profilePic} src={(profilePic || context.user?.picture) ?? UserPlaceholder} alt='' />
                                <div className={styles.userName}>{`${context.user?.name} ${context.user?.surname}`}</div>

                                {editMode &&
                                    <Button
                                        style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                        additionalClass={styles.editPictureButton}
                                        onClick={() => { setDialogOpen(true) }}>
                                        <ImageEditIcon></ImageEditIcon>
                                        {t('profile.edit').toUpperCase()}
                                    </Button>
                                }
                            </div>
                            {!editMode &&
                                <>
                                    <Button style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                        onClick={() => { setEditMode(true) }}>
                                        <EditIcon></EditIcon>
                                        {t('profile.edit').toUpperCase()}
                                    </Button>
                                    <div className={styles.profileInfo}>
                                        <div className={styles.profileRow}>
                                            <div className={styles.profileLabel}>
                                                <EmailIcon></EmailIcon>
                                                <span>email</span>
                                            </div>
                                            <div className={styles.profileRowValue}>
                                                {context.user?.email}
                                            </div>
                                        </div>
                                        <div className={styles.profileRow}>
                                            <div className={styles.profileLabel}>
                                                <AgeIcon></AgeIcon>
                                                <span>{t('profile.age')}</span>
                                            </div>
                                            <div className={styles.profileRowValue}>
                                                {context.user?.age}
                                            </div>
                                        </div>
                                        <div className={styles.profileRow}>
                                            <div className={styles.profileLabel}>
                                                <CityIcon></CityIcon>
                                                <span>{t('profile.city')}</span>
                                            </div>
                                            <div className={styles.profileRowValue}>
                                                {context.user?.city}
                                            </div>
                                        </div>
                                        <div className={styles.profileRow}>
                                            <div className={styles.profileLabel}>
                                                <BackpackIcon />
                                                <span>{t('profile.employment')}</span>
                                            </div>
                                            <div className={styles.profileRowValue}>
                                                {t(`profile.employments.${context.user?.employment}`)}
                                            </div>
                                        </div>
                                        {context.user?.university &&
                                            <div className={styles.profileRow}>
                                                <div className={styles.profileLabel}>
                                                    <UniversityIcon></UniversityIcon>
                                                    <span>{t('profile.university')}</span>
                                                </div>
                                                <div className={styles.profileRowValue}>
                                                    {context.user?.university}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <button className={styles.logout} onClick={handleLogout}>
                                        <LogoutIcon />
                                        Logout
                                    </button>
                                </>
                            }
                        </div>
                    </div>

                    <div className={styles.columnRight}>
                        {editMode &&
                            <>
                                <Card style={{ backgroundColor: 'var(--background-color)', flex: 1 }}>
                                    <div className={styles.editProfileCard}>
                                        <div className={typo.subtitle}>{t('profile.edit')} {t('profile.title')}</div>
                                        <div className={styles.editProfileRow}>
                                            <TextInput value={context.user?.name} type="text" placeholder={t('profile.name')} onKeyUp={onNameChange}></TextInput>
                                            <TextInput value={context.user?.surname} type="text" placeholder={t('profile.surname')} onKeyUp={onSurnameChange}></TextInput>
                                        </div>
                                        <div className={styles.editProfileRow}>
                                            <Select style={{ fontSize: '1.25rem !important' }} selected={employmentOptions.find(o => o.value === context.user?.employment)} options={employmentOptions} onSelect={(item) => {
                                                setEmployment(item.value)
                                            }} />
                                            <Select style={{ fontSize: '1.25rem !important' }} selected={genders.find(o => o.value === context.user?.gender)} options={genders} onSelect={(item) => {
                                                setGender(item.value)
                                            }} />
                                        </div>
                                        {["student", "studentWorker"].includes(employment) &&
                                            <div className={styles.editProfileRow}>
                                                <TextInput value={context.user?.university} type="text" placeholder={t('profile.university')} onKeyUp={onUniversityChange} suggestions={universities.map(u => u.name)}></TextInput>
                                            </div>
                                        }
                                        <div className={styles.editProfileRow}>
                                            <TextInput value={context.user?.age} type="number" placeholder={capitalize(t('profile.age'))} onKeyUp={onAgeChange}></TextInput>
                                            <TextInput value={context.user?.city} type="text" placeholder={capitalize(t('profile.city'))} onKeyUp={onCityChange} autocomplete="off" suggestions={comuni.map(c => `${c.nome} (${c.sigla})`)}></TextInput>
                                        </div>
                                        <div className={styles.error}>
                                            {error && error.code === -1 &&
                                                <span className={typo.caption}>
                                                    {error.message}
                                                </span>
                                            }
                                        </div>
                                        {auth.currentUser.providerData.map(p => p.providerId).includes('password') &&
                                            <>
                                                <div className={typo.subtitle}>{t('profile.changePassword')}</div>
                                                <TextInput value={oldPassword} type="password" placeholder={t('profile.oldPassword')} onKeyUp={onOldPasswordChange} autocomplete='off'></TextInput>
                                                <TextInput value={newPassword} type="password" placeholder={t('profile.newPassword')} onKeyUp={onNewPasswordChange} autocomplete='new-password'></TextInput>
                                                <TextInput value={repeatNewPassword} type="password" placeholder={t('profile.repeatNewPassword')} onKeyUp={onRepeatNewPasswordChange} autocomplete='new-password'></TextInput>
                                                <div className={styles.error}>
                                                    {error && error.code === 0 &&
                                                        <span className={typo.caption}>
                                                            {error.message}
                                                        </span>
                                                    }
                                                </div>
                                            </>
                                        }
                                        <div className={styles.editProfileSaveRow}>
                                            <Button appearance="text" style={{ color: 'var(--secondary)' }}>{t('profile.deleteAccount')}</Button>
                                            <Button style={{ padding: '1rem' }} onClick={onSaveChanges} disabled={loading || !userChanged || error}>{t('profile.saveChanges').toUpperCase()}</Button>
                                        </div>
                                    </div>
                                </Card>
                            </>
                        }
                        {!editMode &&
                            <>
                                <Card style={{ backgroundColor: 'var(--background-color)' }}>
                                    <div className={styles.surveyCard}>

                                        <div className={styles.row} style={{ justifyContent: 'space-between', padding: 0 }}>
                                            <div className={styles.column} style={{ padding: 0, gap: 0 }}>
                                                <div className={typo.headline}>{t('profile.yourSurvey').toUpperCase()}</div>
                                                <div className={typo.title} style={{ fontSize: '1.3rem', whiteSpace: 'nowrap' }}>Risultati</div>
                                            </div>
                                            <div className={styles.column} style={{ justifyContent: 'flex-start', padding: 0 }}>
                                                <button className={styles.surveyButton} onClick={() => {
                                                    navigate("/survey")
                                                }}>CAMBIA RISPOSTE</button>
                                            </div>
                                        </div>

                                        <div className={styles.surveyInner}>
                                            {width >= 1030 &&
                                                <div className={[styles.column, styles.surveyData].join(' ')}>

                                                    {radarDatasets.map(dataset => {
                                                        return dataset.values.map((value, index) => ({
                                                            value,
                                                            label: dataset.labels[index],
                                                            color: dataset.colors[index]
                                                        }));
                                                    }).flat().slice(0, 3).map(data => {
                                                        return (
                                                            <div className={styles.chartCell} key={data.label}>
                                                                <div className={styles.chartRow}>
                                                                    <div className={styles.chartColumn}>
                                                                        <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                                                                    </div>
                                                                    <div className={styles.chartColumn}>
                                                                        <div className={styles.chartDataTitle}>{data.value}</div>
                                                                        <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            <div className={[styles.column, styles.surveyChart].join(' ')}>
                                                <RadarChart data={radarDatasets} options={radarOptions}></RadarChart>
                                            </div>

                                            <div className={[styles.column, styles.surveyData].join(' ')}>
                                                {radarDatasets.map(dataset => {
                                                    return dataset.values.map((value, index) => ({
                                                        value,
                                                        label: dataset.labels[index],
                                                        color: dataset.colors[index]
                                                    }));
                                                }).flat().slice(width >= 1030 ? 3 : 0).map(data => {
                                                    return (
                                                        <div className={styles.chartCell} key={data.label}>
                                                            <div className={styles.chartRow}>
                                                                <div className={styles.chartColumn}>
                                                                    <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                                                                </div>
                                                                <div className={styles.chartColumn}>
                                                                    <div className={styles.chartDataTitle}>{data.value}</div>
                                                                    <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                </Card>

                                <Card style={{ backgroundColor: 'var(--background-color)' }}>
                                    <div className={styles.preferenceCard}>
                                        <div className={styles.row} style={{ justifyContent: 'space-between', padding: 0 }}>
                                            <div className={styles.column} style={{ padding: 0, gap: 0 }}>
                                                <div className={typo.headline}>LE TUE PREFERENZE</div>
                                            </div>
                                        </div>
                                        <div className={styles.preferenceInner}>
                                            <div className={styles.preference}>
                                                <div className={styles.preferenceLabel}>
                                                    Azienda dei tuoi sogni
                                                </div>
                                                <div className={styles.preferenceValue}>
                                                    <CityIcon /> {career?.name ?? "-"}
                                                </div>
                                            </div>
                                            <div className={styles.preference}>
                                                <div className={styles.preferenceLabel}>
                                                    RAL dei tuoi sogni
                                                </div>
                                                <div className={styles.preferenceValue}>
                                                    <MoneybagIcon /> {salary ?? "-"}
                                                </div>
                                            </div>
                                            <div className={styles.preference}>
                                                <div className={styles.preferenceLabel}>
                                                    Worktime dei tuoi sogni
                                                </div>
                                                <div className={styles.preferenceValue}>
                                                    <ClockIcon />  {workHour ?? "-"}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Card>

                                {courses && courses.filter(c => c.status === CourseStatus.InProgress).length > 0 &&
                                    <Card style={{ backgroundColor: 'var(--background-color)' }}>
                                        <div className={styles.coursesCard}>
                                            <div className={styles.row} style={{ justifyContent: 'space-between', padding: 0 }}>
                                                <div className={styles.column} style={{ padding: 0, gap: 0 }}>
                                                    <div className={typo.headline}>I TUOI CORSI</div>
                                                </div>
                                            </div>
                                            <Each of={courses.filter(c => c.status === CourseStatus.InProgress)} render={(course) => {
                                                return (
                                                    <PathCourseCard course={course} />
                                                )
                                            }} />
                                        </div>
                                    </Card>
                                }

                                {macros && macros.length > 0 &&
                                    <Card style={{ backgroundColor: 'var(--background-color)' }}>
                                        <div className={styles.macrosCard}>
                                            <div className={styles.row} style={{ justifyContent: 'space-between', padding: 0 }}>
                                                <div className={styles.column} style={{ padding: 0, gap: 0 }}>
                                                    <div className={typo.headline}>AREE DI INTERESSE</div>
                                                </div>

                                            </div>
                                            <div className={styles.macros}>

                                                <Each of={macros} render={(macro) => {
                                                    return (
                                                        <div className={styles.macro}>
                                                            <div className={styles.macroHeader} style={{ backgroundImage: macro.background }}>
                                                                <div className={styles.macroTitle}>
                                                                    {macro.name}
                                                                </div>
                                                                <div className={styles.macroSubtitle}>
                                                                    {macro.subtitle}
                                                                </div>
                                                            </div>
                                                            <div className={styles.macroAction}>
                                                                <Button fullWidth inverse accentColor={"var(--secondary)"}
                                                                    onClick={async () => {
                                                                        await setMacroInterest(macro.id)
                                                                        setTimeout(() => {
                                                                            setMacros((prev) => {
                                                                                return prev.filter(m => m.id !== macro.id)
                                                                            })
                                                                        }, 200)
                                                                    }}
                                                                >
                                                                    NON MI INTERESSA
                                                                    <FavoriteRemoveIcon />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )
                                                }} />
                                            </div>
                                        </div>
                                    </Card>
                                }
                                {/* <div className={[styles.row, styles.carreer].join(' ')} style={{ padding: 0 }}>
                                    <Card style={{ backgroundColor: 'var(--background-color)', flex: 1 }}>
                                        <div className={styles.column} style={{ width: '100%' }}>
                                            <div className={styles.row} style={{ justifyContent: 'space-between', padding: 0 }}>
                                                <div className={styles.column} style={{ padding: 0, gap: 0 }}>
                                                    <div className={typo.headline}>{t('profile.yourCarreer').toUpperCase()}</div>
                                                    <div className={typo.title} style={{ fontSize: '1.3rem', whiteSpace: 'nowrap' }}>Financial Advisor</div>
                                                </div>
                                                <div className={styles.column} style={{ justifyContent: 'flex-start', padding: 0 }}>
                                                    <button className={styles.changeButton}>
                                                        CAMBIA
                                                        <ReloadIcon></ReloadIcon>
                                                    </button>
                                                </div>
                                            </div>
                                            <CircularProgess data={circularData}></CircularProgess>
                                            <div className={styles.row} style={{ padding: 0, justifyContent: 'center' }}>
                                                <div className={styles.circularChartColumn}>
                                                    {circularData.slice(0, 2).map(data => {
                                                        return (
                                                            <div className={styles.chartCell} key={data.label}>
                                                                <div className={styles.chartRow}>
                                                                    <div className={styles.chartColumn}>
                                                                        <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                                                                    </div>
                                                                    <div className={styles.chartColumn}>
                                                                        <div className={styles.chartDataTitle}>{data.value}%</div>
                                                                        <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className={styles.circularChartColumn}>
                                                    {circularData.slice(2).map(data => {
                                                        return (
                                                            <div className={styles.chartCell} key={data.label}>
                                                                <div className={styles.chartRow}>
                                                                    <div className={styles.chartColumn}>
                                                                        <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                                                                    </div>
                                                                    <div className={styles.chartColumn}>
                                                                        <div className={styles.chartDataTitle}>{data.value}%</div>
                                                                        <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>

                                    <Card style={{ backgroundColor: 'var(--background-color)', flex: 1 }}>
                                        <div className={styles.carrersColumn}>
                                            <div className={typo.headline}>{t('profile.otherCarrers').toUpperCase()}</div>
                                            <div className={typo.body} style={{ padding: '.5rem 0' }}>{t('profile.otherCarrersDesc')}</div>
                                            <div className={styles.careers}>
                                                <Each of={otherCarrers} render={(c) => {
                                                    return (
                                                        <CareerProgress name={c.name} />
                                                    )
                                                }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button appearance="text">VEDI TUTTE</Button>
                                            </div>
                                        </div>
                                    </Card>
                                </div> */}
                            </>
                        }
                    </div>
                </div>

                <Dialog
                    open={dialogOpen}
                    onClose={() => {
                        setDialogOpen(false)
                        setCropMode(false)
                        setAlert(null)
                    }}
                    title={alert ? alert.title : "Immagine del profilo"}>
                    {!alert &&
                        <>
                            {!cropMode &&
                                <Dropzone
                                    onDrop={(files) => {
                                        if (files[0]) {
                                            setImgFile(files[0])
                                            setCropMode(true)
                                        }
                                    }}
                                    accept={{
                                        'image/jpeg': [],
                                        'image/png': [],
                                        'image/heic': []
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <section style={{ display: 'flex', width: '100%' }}>
                                            <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                                <input {...getInputProps()} />
                                                <div className={styles.editPictureDropzone}>
                                                    <InsertImageIllustration></InsertImageIllustration>
                                                    <div className={styles.editPictureLabel}>
                                                        {t('profile.dropImage')}
                                                    </div>
                                                    <Button
                                                        style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                        additionalClass={styles.editPictureButton}
                                                    >
                                                        {t('profile.chooseImage').toUpperCase()}
                                                    </Button>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            }
                            {cropMode &&
                                <>
                                    <ImageCropper
                                        src={URL.createObjectURL(imgFile)}
                                        onSave={(image) => {
                                            setProfilePic(image)
                                            setCropMode(false)
                                            setDialogOpen(false)
                                        }}
                                        onCancel={() => { setCropMode(false) }}
                                        compression={{ quality: 0.7, maxWidth: 300, maxHeight: 300 }}
                                    >
                                    </ImageCropper>
                                </>
                            }
                        </>
                    }
                    {alert &&
                        <div className={styles.editPictureLabel} style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {alert.message}
                        </div>
                    }
                </Dialog>
            </div>
        </HeaderFooterLayout>
    )
}

export default Profile

