import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"
import { Each } from "../../common/Each"
import { CorrectionType, TestType } from "../../enums"
import Radio from "../Radio"
import Skeleton from "../Skeleton"
import styles from "./TestChoice.module.css"

const TestChoice = ({
  id = null,
  index = 1,
  answers = [],
  text = "",
  testType = null,
  singleChoice = false,
  onSelect = () => { },
  responses = null,
  loading = false,
  correctionType = null // se presente si mostrano i risultati
}) => {
  const { t } = useTranslation()
  const [userAnswers, setUserAnswers] = useState(answers.map(a => {
    return {
      id: a.id,
      value: false
    }
  }))

  const type = useMemo(() => {
    if (!testType)
      return null

    switch (testType) {
      case TestType.SingleChoice: return "singleChoice"
      case TestType.MultipleChoice: return "multipleChoice"
      case TestType.TrueFalse: return "trueFalse"
      case TestType.Matching: return "matching"
      case TestType.TextCompletion: return "textCompletion"
      default: console.error("invalid test type"); break
    }
  }, [testType])

  useEffect(() => {
    if (responses && !correctionType) {
      setUserAnswers([...responses])
    }
  }, [answers])


  useEffect(() => {
    // const isValid = isTestChoiceValid(userAnswers)
    onSelect(id, testType, true, userAnswers)
  }, [userAnswers])

  const onAnswerSelected = useCallback((index) => {
    if (userAnswers[index].value) {
      setUserAnswers((p) => {
        p[index].value = false
        return [...p]
      })
    } else {
      setUserAnswers((p) => {
        if (singleChoice) {
          p = answers.map(a => {
            return {
              id: a.id,
              value: false
            }
          })
        }
        p[index].value = true
        return [...p]
      })
    }
  }, [userAnswers, answers])

  return (
    <div className={styles.container}>
      {
        loading === true &&
        <>
          <Skeleton width={"100%"} height={"1.125rem"} borderRadius={"12px"} />
          <Skeleton width={"33%"} height={"1.125rem"} borderRadius={"12px"} />
          <Each
            of={[1, 2, 3, 4]}
            render={() => (
              <div className={styles.answer}>
                <Skeleton type="circle" width={"20px"} height={"20px"} />
                <Skeleton width={"256px"} height={"16px"} borderRadius={"12px"} />
              </div>
            )}
          />
        </>
      }

      {
        loading === false && correctionType === null &&
        <>
          <div className={styles.text}>{t(`tests.request`)} {index}: {text}</div>
          <Each
            of={answers}
            render={(answer, index) => (
              <div className={userAnswers.find(ua => ua.id === answer.id)?.value ? `${styles.answer} ${styles.selected}` : styles.answer}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onAnswerSelected(index)
                }}
              >
                <Radio value={userAnswers.find(ua => ua.id === answer.id)?.value ?? false} onChange={() => onAnswerSelected(index)} shape={singleChoice ? "default" : "rect"} />
                <ReactMarkdown children={`${t("tests.answer")} ${index + 1} - ${answer.text}`} className={"inlineMarkdown"} />
              </div>
            )}
          />
        </>
      }

      {
        loading === false && correctionType &&
        <>
          <div className={styles.text}>{t(`tests.request`)} {index}: {text}</div>
          <Each
            of={answers}
            render={(answer, index) => {
              let userResponse = null
              let className = styles.answerSolution
              if (responses.responses) {
                const ur = responses.responses.find(r => r.id === answer.id)
                if (ur) {
                  userResponse = ur
                  if ((answer.isCorrect && userResponse.value) || (answer.isCorrect && correctionType === CorrectionType.Solutions)) {
                    className = `${styles.answerSolution} ${styles.correct}`
                  } else if (userResponse.value && !answer.isCorrect) {
                    className = `${styles.answerSolution} ${styles.wrong}`
                  }
                  if (ur.value === true) {
                    className += ` ${styles.selected}`
                  }
                }
              }
              return (

                <div className={className}>
                  <Radio value={userResponse.value || (answer.isCorrect && correctionType === CorrectionType.Solutions)} error={userResponse.value && !answer.isCorrect} shape={singleChoice ? "default" : "rect"} />
                  <ReactMarkdown children={`${t("tests.answer")} ${index + 1} - ${answer.text}`} className={"inlineMarkdown"} />
                </div>
              )
            }}
          />
        </>
      }
    </div>
  )
}


export default TestChoice
